import React from "react";
import { ConsoleApplicationLayout } from "../../layouts/ConsoleApplicationLayout";
import Stack from "@mui/material/Stack";
import TransactionRecord from "./TransactionRecord";
import CashflowItem from "./CashflowItem";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

export default function ({
  current_user,
  fx_rates,
  upcoming_cashflow_items,
  past_cashflow_items,
  upcoming_transactions,
  planned_transactions,
  past_transactions,
}) {
  const upcoming_items = upcoming_cashflow_items
    .concat(upcoming_transactions)
    .concat(planned_transactions)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const past_items = past_cashflow_items
    .concat(past_transactions)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
    );

  return (
    <ConsoleApplicationLayout current_user={current_user}>
      <h1>Automation</h1>
      <Stack spacing={2}>
        <Card>
          <CardHeader title="Upcoming" />
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              {upcoming_items.map(
                (item) =>
                  (item.date && <CashflowItem key={item.id} item={item} />) || (
                    <TransactionRecord
                      key={item.id}
                      transaction={item}
                      currency={item.src_currency}
                      fx_rates={fx_rates}
                    />
                  ),
              )}
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Past" />
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              {past_items.map(
                (item) =>
                  (item.date && <CashflowItem key={item.id} item={item} />) || (
                    <TransactionRecord
                      key={item.id}
                      transaction={item}
                      currency={item.src_currency}
                      fx_rates={fx_rates}
                    />
                  ),
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </ConsoleApplicationLayout>
  );
}
