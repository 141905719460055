import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import Avatar from "react-avatar-edit";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import { Form } from "../common/Form";
import I18n from "../common/I18n";
import SubmitButton from "../common/SubmitButton";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";

const PHONE_REGX =
  /^$|^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: "name" })),
  email: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: "email_address" }))
    .email(i18n.t("field_is_incorrect", { field: i18n.t("email_address") })),
  phone_number: yup
    .string()
    .matches(
      PHONE_REGX,
      i18n.t("field_is_incorrect", { field: i18n.t("phone_number") }),
    ),
});

function EditDrawerContent({ current_user }) {
  const [preview, setPreview] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: current_user.name,
      email: current_user.email,
      phone_number: current_user.profile?.phone_number || "",
    },
    resolver: yupResolver(SCHEMA),
  });
  const onSubmitAvatar = (e) => {
    if (preview) return true;
    e.preventDefault();
    return false;
  };
  const phone_number = watch("phone_number");

  return (
    <Stack spacing={2}>
      <Form
        action={routes.user()}
        method="put"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <I18n user_name />
            </Typography>
            <TextField
              fullWidth
              required
              variant="standard"
              label={i18n.t("user_name")}
              helperText={errors.name?.message}
              {...register("name")}
              error={"name" in errors}
              autoFocus
            />
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" sx={{ my: 2 }}>
              <I18n email_address />
            </Typography>
            <TextField
              fullWidth
              required
              variant="standard"
              label={i18n.t("email_address")}
              helperText={errors.email?.message}
              {...register("email")}
              error={"email" in errors}
            />
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" sx={{ my: 2 }}>
              <I18n phone_number />
            </Typography>
            <MuiPhoneNumber
              defaultCountry={"sg"}
              fullWidth
              variant="standard"
              label={i18n.t("phone_number")}
              helperText={errors.phone_number?.message}
              name="phone_number"
              value={phone_number}
              onChange={(value) => setValue("phone_number", value)}
              error={"phone_number" in errors}
            />
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton variant="contained" type="submit">
              <I18n update />
            </SubmitButton>
          </CardActions>
        </Card>
      </Form>
      <Form action={routes.user()} method="put" onSubmit={onSubmitAvatar}>
        <input type="hidden" name="image" value={preview || ""} />
        <Card>
          <CardHeader title={i18n.t("avatar")} />
          <Divider />
          <CardContent>
            <Avatar width={120} height={120} onCrop={setPreview} />
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton variant="contained" type="submit">
              <I18n update />
            </SubmitButton>
          </CardActions>
        </Card>
      </Form>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("user_profile")}
      icon={PencilIcon}
    >
      <EditDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
