import routesJson from "../routes.json";

function path_params(path, params) {
  const with_host = params?.with_host;
  params = { ...params };
  delete params["with_host"];
  let result_path = path.replace(/:[a-zA-Z_]+/g, (match) => {
    const key = match.slice(1);
    if (!(key in params)) throw new Error(`No such ${key} in path params.`);
    const val = params[key];
    delete params[key];
    return val;
  });
  if (Object.keys(params).length > 0) {
    let search = new URLSearchParams();
    Object.keys(params).forEach((key) => search.set(key, params[key]));
    return result_path + "?" + search.toString();
  }
  if (with_host) return `${location.protocol}//${location.host}` + result_path;
  return result_path;
}

export default Object.keys(routesJson).reduce((r, key) => {
  const path = routesJson[key];
  r[key] = (params) => path_params(path, params);
  return r;
}, {});
