import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import ArrowRightOnRectangleIcon from "@heroicons/react/24/solid/ArrowRightOnRectangleIcon";
import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import routes from "../../utils/routes";
import I18n from "../common/I18n";

export const AccountPopover = ({
  anchorEl,
  onClose,
  open,
  account,
  current_user,
}) => {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 240 } }}
    >
      <Box sx={{ py: 1.5, px: 2 }}>
        <Typography variant="overline" sx={{ lineHeight: 1.5 }}>
          {account.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant="body2">
            {current_user.name}
          </Typography>
          <Chip label={current_user.role} size="small" />
        </Stack>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem href={routes.user()} onClick={onClose} component="a">
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <UserCircleIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>
            <I18n user_profile />
          </ListItemText>
          {!current_user.email_verified && (
            <Badge color="warning" variant="dot" />
          )}
        </MenuItem>
        <MenuItem
          href={routes.users_sign_out()}
          component="a"
          data-turbo="false"
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <ArrowRightOnRectangleIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>
            <I18n sign_out />
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
