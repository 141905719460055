import React, { useState, useEffect, createRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { UserApplicationLayout } from "../../layouts/UserApplicationLayout";
import EditDrawer from "./EditDrawer";
import { Form, submitForm } from "../../common/Form";
import AppCard from "../../account/app_integrations/AppCard";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import api from "../../../utils/api";

const SubForm = React.forwardRef((_props, ref) => {
  return (
    <Form action={routes.user_security()} method="put" ref={ref}>
      <input type="hidden" name="identifier" value="" />
      <input type="hidden" name="user_id" value="" />
    </Form>
  );
});

const SHOW_AUDITS_LIMIT = 3;

export default function ({ app_integrations, ...props }) {
  const { current_user } = props;
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onClick = () => setOpen(true);
  const [showAudits, setShowAudits] = useState(false);
  const [audits, setAudits] = useState([]);
  const toggleShowAudits = () => setShowAudits(!showAudits);
  const retrieveAudits = () => {
    api({
      url: routes.retrieve_audits_user(),
      method: "get",
      onStart: () => undefined,
      onError: () => undefined,
      onSuccess: (response) => setAudits(response),
    });
  };
  const auditsLimited = showAudits
    ? audits
    : audits.slice(0, SHOW_AUDITS_LIMIT);
  useEffect(retrieveAudits, []);
  const formRef = createRef();
  const onChangeLogin = (app_integration) => {
    const provider =
      current_user.profile?.providers &&
      current_user.profile.providers.find(
        (provider) => provider.identifier == app_integration.identifier,
      );
    if (provider) {
      formRef.current.querySelector("input[name=identifier]").value =
        app_integration.identifier;
      formRef.current.querySelector("input[name=user_id]").value = "";
      submitForm(formRef.current);
    } else {
      window.open(
        routes.initiate_account_app_integrations({
          identifier: app_integration.identifier,
          create_login: true,
        }),
        "CashWise - " + i18n.t("account_integration"),
        [
          "height=700",
          "width=550",
          "menubar=no",
          "toolbar=no",
          "location=no",
          "status=no",
          "resizable=no",
        ].join(","),
      );
    }
  };
  window.handleAppIntegrationSelect = function (app_integration, app_user_id) {
    formRef.current.querySelector("input[name=identifier]").value =
      app_integration.identifier;
    formRef.current.querySelector("input[name=user_id]").value = app_user_id;
    submitForm(formRef.current);
  };

  return (
    <UserApplicationLayout {...props}>
      <Stack spacing={2}>
        <Card>
          <CardHeader
            title={i18n.t("password")}
            action={
              <Button onClick={onClick} variant="contained">
                <I18n edit />
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <Typography variant="monospace" sx={{ my: 2 }}>
              ************
            </Typography>
          </CardContent>
        </Card>
        {app_integrations.map((app_integration) => (
          <AppCard
            key={app_integration.id}
            app={app_integration.app}
            action={
              <FormControlLabel
                label={<I18n login_with name={app_integration.app.name} />}
                control={
                  <Switch
                    checked={
                      (current_user.profile?.providers &&
                        current_user.profile.providers.find(
                          (provider) =>
                            provider.identifier == app_integration.identifier,
                        )) ||
                      false
                    }
                    onChange={() => onChangeLogin(app_integration)}
                  />
                }
              />
            }
          />
        ))}
        <Typography variant="h6" sx={{ fontSize: "75%", pl: 2, pt: 2 }}>
          <I18n login_audits />
        </Typography>
        <Timeline>
          {auditsLimited.map((audit, i) => (
            <TimelineItem
              key={i}
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
                minHeight: auditsLimited.length - 1 == i ? 0 : undefined,
              }}
            >
              <TimelineOppositeContent color="textSecondary">
                <Typography variant="subtitle2" sx={{ fontSize: "75%" }}>
                  {i18n.toTime(
                    "time.formats.short",
                    Date.parse(audit.action_at),
                  )}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {auditsLimited.length - 1 > i && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2" sx={{ fontSize: "75%" }}>
                  {audit.comment}
                  &nbsp; (IP: {audit.remote_address})
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
          {audits.length > auditsLimited.length && (
            <Button onClick={toggleShowAudits}>
              <I18n show_more />
            </Button>
          )}
          {audits.length == 0 && (
            <Typography variant="subtitle2" sx={{ p: 2 }}>
              <I18n no_login_audits />
            </Typography>
          )}
        </Timeline>
      </Stack>
      <EditDrawer open={open} onClose={onClose} {...props} />
      <SubForm ref={formRef} />
    </UserApplicationLayout>
  );
}
