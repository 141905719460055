import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import BankCard from "./BankCard";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import currencyName from "../../../utils/currency-name";

function TokenObtainError(identifier, handleAccountIntegrationCancel) {
  return (
    <PopupApplicationLayout title="account_integration">
      <Stack spacing={2}>
        <Typography variant="body1">
          <b>
            <I18n
              account_integration_credentials_need_to_be_updated
              name={i18n.t("bank_account")}
            />
          </b>
          &nbsp;
          <I18n click_the_following_button_to_update />
        </Typography>
        <div />
        <Button
          size="large"
          color="warning"
          variant="contained"
          href={routes.initiate_account_integrations({
            identifier,
            continue_select: true,
          })}
        >
          <I18n update_credentials />
        </Button>
        <Button
          onClick={handleAccountIntegrationCancel}
          sx={{ marginLeft: "auto!important", marginTop: "6px!important" }}
        >
          <I18n cancel />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}

function GetBalanceError(handleAccountIntegrationCancel) {
  return (
    <PopupApplicationLayout title="account_integration">
      <Stack spacing={2}>
        <Typography variant="body1">
          <I18n account_integration_get_balance_error />
        </Typography>
        <div />
        <Button variant="contained" onClick={handleAccountIntegrationCancel}>
          <I18n cancel />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}

export default function ({ error, bank_accounts, identifier, flash }) {
  const onClick = () => {
    if (!error && bank_accounts.length > 0) {
      window.opener.handleAccountIntegrationSelect(identifier, bank_accounts);
      window.close();
    } else {
      window.opener.handleAccountIntegrationCancel();
      window.close();
    }
  };

  if (error == "get_token") return TokenObtainError(identifier, onClick);
  if (error == "get_balance") return GetBalanceError(onClick);

  return (
    <PopupApplicationLayout title="account_integration" flash={flash}>
      <Stack spacing={2}>
        <Typography variant="body1">
          <I18n integration_success />
        </Typography>
        <div />
        {bank_accounts.map((bank_account) => (
          <BankCard
            key={bank_account.id}
            bank={bank_account}
            suspended={bank_account.status == "suspended"}
          >
            <Typography variant="body2">
              <I18n currency />
              :&nbsp;&nbsp;&nbsp;
              <b>{currencyName(bank_account.currency)}</b>
            </Typography>
          </BankCard>
        ))}
        {bank_accounts.length == 0 && (
          <I18n no_balance_is_found_in_currencies />
        )}
        <div />
        <Button fullWidth variant="contained" onClick={onClick}>
          <I18n close />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}
