import React, { useState, useEffect, createRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Switch from "@mui/material/Switch";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import api from "../../../utils/api";
import Dropdown from "../../common/Dropdown";
import AppCard from "./AppCard";
import BankCard from "../integrations/BankCard";
import RefreshButton from "../../common/RefreshButton";
import currencyName from "../../../utils/currency-name";

function Step1_SelectApp({ apps, selectApp, onSelectApp }) {
  // Open popup window to perform OAuth authentication process
  const popupIntegrationWindow = () => {
    window.open(
      routes.initiate_account_app_integrations({ app_id: selectApp.id }),
      "CashWise - " + i18n.t("account_integration"),
      [
        "height=700",
        "width=550",
        "menubar=no",
        "toolbar=no",
        "location=no",
        "status=no",
        "resizable=no",
      ].join(","),
    );
  };

  return (
    <>
      <Stack spacing={2}>
        {apps.map((app) => (
          <AppCard
            key={app.id}
            app={app}
            select={selectApp}
            onSelectApp={onSelectApp}
            description
          />
        ))}
        {apps.length == 0 && <I18n no_apps />}
      </Stack>
      <Box sx={{ mt: 2 }}>
        <SubmitButton
          fullWidth
          disabled={!selectApp}
          variant="contained"
          sx={{ mr: 1 }}
          onClick={popupIntegrationWindow}
        >
          <I18n connect />
        </SubmitButton>
      </Box>
    </>
  );
}

function Step2_AppSettings({
  app,
  app_integration,
  bank_accounts,
  handlePrev,
}) {
  const formRef = createRef();
  const [active, setActive] = useState(true);
  const [appBankAccounts, setAppBankAccounts] = useState([]);
  const [retrievingBankAccounts, setRetrievingBankAccounts] = useState(false);
  const toggleActive = () => setActive(!active);
  const [disablePayouts, setDisablePayouts] = useState(
    app_integration?.data?.disable_payouts ? "true" : "false",
  );
  const onDisablePayoutsChange = (event) =>
    setDisablePayouts(event.target.value);
  const appBankAccountList = (bank_account) => {
    return appBankAccounts
      .filter(
        (_bank_account) => _bank_account.currency == bank_account.currency,
      )
      .map((item) => ({
        // Create a list of bank accounts that can be selected
        value: `${item.account_id}:_:${item.name}:_:${item.currency}`,
        name: `${item.name} - ${currencyName(item.currency)}`,
      }));
  };
  const retrieveBankAccounts = () => {
    if (app_integration)
      api({
        url: routes.retrieve_bank_accounts_account_app_integrations(),
        method: "get",
        data: { identifier: app_integration.identifier },
        onStart: () => setRetrievingBankAccounts(true),
        onError: () => setRetrievingBankAccounts(false),
        onSuccess: (app_bank_accounts) => {
          setAppBankAccounts(app_bank_accounts);
          setRetrievingBankAccounts(false);
        },
      });
  };
  useEffect(() => retrieveBankAccounts(), [app_integration]);

  return (
    <>
      <Form
        ref={formRef}
        action={routes.account_app_integrations({
          identifier: app_integration?.identifier,
        })}
        method="post"
      >
        <Stack spacing={2}>
          <AppCard
            app={app}
            select={true}
            action={
              <Switch name="status" checked={active} onChange={toggleActive} />
            }
          />
          {active && (
            <Card>
              <CardHeader
                title={i18n.t("bill_payment_mode")}
                subheader={i18n.t("bill_payment_mode_subtitle")}
              />
              <Divider />
              <CardContent>
                <FormControl>
                  <RadioGroup
                    name="disable_payouts"
                    value={disablePayouts}
                    onChange={onDisablePayoutsChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={i18n.t("app_integration_disable_payouts")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={i18n.t("app_integration_enable_payouts")}
                    />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
          )}
          {active && (
            <Card>
              <CardHeader
                title={i18n.t("bank_account_mapping")}
                subheader={i18n.t("bank_account_mapping_subtitle")}
              />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  {bank_accounts
                    .filter(
                      (bank_account) =>
                        bank_account.bank_type == "transactional" &&
                        bank_account.status != "suspended",
                    )
                    .map((bank_account) => (
                      <BankCard
                        key={bank_account.id}
                        bank={bank_account.bank}
                        action={
                          <div style={{ display: "flex", minWidth: 240 }}>
                            <Dropdown
                              required
                              fullWidth
                              variant="standard"
                              values={appBankAccountList(bank_account)}
                              label={i18n.t("account_name")}
                              name={`bank_account[${bank_account.id}]`}
                            />
                            <RefreshButton
                              title={i18n.t("refresh")}
                              sx={{ alignSelf: "end", ml: 1 }}
                              onClick={() => retrieveBankAccounts()}
                              loading={retrievingBankAccounts}
                            />
                          </div>
                        }
                      >
                        <Typography variant="body2">
                          <I18n currency />
                          :&nbsp;&nbsp;&nbsp;
                          <b>{currencyName(bank_account.currency)}</b>
                        </Typography>
                      </BankCard>
                    ))}
                  {bank_accounts.filter(
                    (bank_account) =>
                      bank_account.bank_type == "transactional" &&
                      bank_account.status != "suspended",
                  ).length == 0 && (
                    <Typography variant="subtitle2">
                      {i18n.t("no_bank_accounts")}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
        </Stack>
        <Box sx={{ mt: 2 }}>
          <SubmitButton fullWidth variant="contained" type="submit">
            <I18n create />
          </SubmitButton>
          <Button onClick={handlePrev}>
            <I18n prev />
          </Button>
        </Box>
      </Form>
    </>
  );
}

function NewDrawerContent({ onClose, apps, app_integrations, bank_accounts }) {
  const [step, setStep] = useState(0);
  const [selectApp, setSelectApp] = useState(null);
  const [appIntegration, setAppIntegration] = useState(null);
  const onSelectApp = (app) =>
    selectApp?.id == app.id ? setSelectApp(null) : setSelectApp(app);
  window.handleAppIntegrationSelect = function (app_integration) {
    if (app_integration) {
      setAppIntegration(app_integration);
      setStep(1);
    } else onClose();
  };
  window.handleAppIntegrationCancel = function () {
    onClose();
  };
  const integrated_app_ids = app_integrations.map(
    (app_integration) => app_integration.app.id,
  );
  const other_apps = apps.filter(
    (app) => !integrated_app_ids.find((id) => id == app.id),
  );

  return (
    <Stepper orientation="vertical" activeStep={step}>
      <Step>
        <StepLabel>
          <I18n select_app />
        </StepLabel>
        <StepContent>
          <Step1_SelectApp
            apps={other_apps}
            selectApp={selectApp}
            onSelectApp={onSelectApp}
          />
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <I18n configure_app_settings />
        </StepLabel>
        <StepContent>
          <Step2_AppSettings
            app={selectApp}
            app_integration={appIntegration}
            bank_accounts={bank_accounts}
            handlePrev={() => setStep(0)}
          />
        </StepContent>
      </Step>
    </Stepper>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("app_integrations")}
      icon={PlusCircleIcon}
    >
      <NewDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
