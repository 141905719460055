import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowLeftIcon from "@heroicons/react/24/solid/ArrowLeftIcon";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme } from "../theme";
import logoImage from "images/cashwise-logo-wide.png";

const BACK_TO_URL = "https://cashwise.asia";

export const AnalysisApplicationLayout = function ({ children, wide }) {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(22, 53, 92, 0.7)",
          position: "sticky",
          width: "100%",
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          spacing={2}
          sx={{ height: 64 }}
        >
          <a href={BACK_TO_URL}>
            <img src={logoImage} height="48" />
          </a>
        </Stack>
      </Box>
      <Container
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box component="header" sx={{ width: "100%" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <IconButton href={BACK_TO_URL}>
              <SvgIcon>
                <ArrowLeftIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Box>
        <Box sx={{ minWidth: 320, maxWidth: wide ? 720 : 480, width: "100%" }}>
          {children}
        </Box>
      </Container>
    </ThemeProvider>
  );
};
