import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";
import AdjustmentsHorizontalIcon from "@heroicons/react/24/solid/AdjustmentsHorizontalIcon";
import ArrowTrendingUpIcon from "@heroicons/react/24/solid/ArrowTrendingUpIcon";
import routes from "../../utils/routes";

export const MainMenu = [
  { icon: ChartBarIcon, title: "dashboard", route: routes.dashboard },
  {
    icon: CurrencyDollarIcon,
    title: "cash_planning",
    route: routes.transactions,
  },
  {
    icon: ArrowTrendingUpIcon,
    title: "account_trends",
    route: routes.account_trends,
  },
];

export const AdminMenu = [
  {
    icon: AdjustmentsHorizontalIcon,
    title: "account_settings",
    route: routes.account,
  },
];
