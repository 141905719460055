import React from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import SvgIcon from "@mui/material/SvgIcon";
import I18n from "../common/I18n";

export const SideNavItem = ({ params, icon, url, route, title }) => {
  const href = route ? route(params) : url;
  const currentPath = window.location.pathname;
  const hrefPath = href.replace(/\?.*$/, "");
  const active =
    hrefPath == currentPath.slice(0, hrefPath.length) &&
    currentPath.substr(hrefPath.length, 1) != "_";
  const Icon = icon;

  return (
    <li>
      <ButtonBase
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: "16px",
          pr: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(active && {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          }),
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          },
        }}
        component="a"
        href={href}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              color: "neutral.400",
              display: "inline-flex",
              justifyContent: "center",
              mr: 2,
              ...(active && {
                color: "primary.main",
              }),
            }}
          >
            <SvgIcon>
              <Icon />
            </SvgIcon>
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: "neutral.100",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
            ...(active && {
              color: "common.white",
            }),
          }}
        >
          <I18n t={title} />
        </Box>
      </ButtonBase>
    </li>
  );
};
