import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowLongRightIcon from "@heroicons/react/24/solid/ArrowLongRightIcon";
import ArchiveBoxXMarkIcon from "@heroicons/react/24/solid/ArchiveBoxXMarkIcon";
import ArrowUturnUpIcon from "@heroicons/react/24/solid/ArrowUturnUpIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import EllipsisVerticalIcon from "@heroicons/react/24/solid/EllipsisVerticalIcon";
import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import DocumentArrowDownIcon from "@heroicons/react/24/outline/DocumentArrowDownIcon";
import dayjs from "dayjs";
import Confirm from "../../common/Confirm";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import currencyName from "../../../utils/currency-name";

export default function ({ transaction, state, currency, onClick, fx_rates }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <Grid
      container
      spacing={0}
      onClick={onClick}
      sx={{
        p: 2,
        alignItems: "center",
        borderRadius: 1,
        "&:not(:last-child)": { borderBottom: "solid 0.5px #eee" },
        bgcolor:
          transaction.status == "canceled" ? "neutral.200" : "transparent",
      }}
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {transaction.status == "failed" && (
          <div>
            <SvgIcon>
              <XMarkIcon color="red" />
            </SvgIcon>
            &nbsp;
          </div>
        )}
        {transaction.status == "canceled" && (
          <div>
            <SvgIcon>
              <XMarkIcon color="grey" />
            </SvgIcon>
            &nbsp;
          </div>
        )}
        <Typography variant="body1">
          {transaction.account.id}: <b>{transaction.account.name}</b>
        </Typography>
        <Typography variant="body2">
          <I18n l="date.long" v={transaction.planned_at} />
          {transaction.transaction_data?.early_execution && (
            <Typography
              variant="subtitle2"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <SvgIcon fontSize="small" color="warning">
                <ExclamationCircleIcon />
              </SvgIcon>
              &nbsp;
              <I18n early_exit />
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={7}
        md={5}
        sx={{ display: "flex", justifyContent: "start" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          }}
        >
          {(transaction.src_currency != transaction.dst_currency &&
            transaction.dst_currency == currency && (
              <Typography variant="body2" sx={{ mx: 1 }}>
                <b>
                  {transaction.src_currency}/{transaction.dst_currency}
                </b>
                <br />
                <I18n fx_conversion />
              </Typography>
            )) || (
            <>
              <Avatar
                variant="rounded"
                sx={{ width: 28, height: 28, mr: 1 }}
                src={transaction.src_bank_account.image}
              />
              <Typography
                variant="body2"
                sx={{
                  display: { xs: "none", md: "block", whiteSpace: "nowrap" },
                }}
              >
                {transaction.src_bank_account.short_name}
              </Typography>
            </>
          )}
        </div>
        <SvgIcon>
          <ArrowLongRightIcon />
        </SvgIcon>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          }}
        >
          {(transaction.src_currency != transaction.dst_currency &&
            transaction.src_currency == currency && (
              <Typography variant="body2" sx={{ mx: 1 }}>
                <b>
                  {transaction.src_currency}/{transaction.dst_currency}
                </b>
                <br />
                <I18n fx_conversion />
              </Typography>
            )) || (
            <>
              <Avatar
                variant="rounded"
                sx={{ width: 28, height: 28, mr: 1 }}
                src={transaction.dst_bank_account.image}
              />
              <Typography
                variant="body2"
                sx={{
                  display: { xs: "none", md: "block", whiteSpace: "nowrap" },
                }}
              >
                {transaction.dst_bank_account.name}
              </Typography>
            </>
          )}
        </div>
      </Grid>
      <Grid
        item
        xs={4}
        md={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div>
          {currencyName(currency || transaction.dst_currency)}
          &nbsp;
          {(transaction.src_currency == currency &&
            transaction.src_amount == 0 && (
              <Tooltip title={i18n.t("automation_fx_conversion_subtitle")}>
                <b>
                  <I18n
                    l="number"
                    v={
                      transaction.dst_amount *
                      fx_rates[transaction.dst_currency][
                        transaction.src_currency
                      ]
                    }
                    delimiter=","
                    precision={2}
                  />
                </b>
                <Typography component="span" variant="body2">
                  &nbsp;
                  <I18n estimated />
                </Typography>
              </Tooltip>
            )) || (
            <b>
              <I18n
                l="number"
                v={
                  transaction.src_currency == currency
                    ? transaction.src_amount
                    : transaction.dst_amount
                }
                delimiter=","
                precision={2}
              />
            </b>
          )}
        </div>
        {dayjs(transaction.completed_at).isAfter(dayjs(), "day") && (
          <>
            <Typography
              variant="body2"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <I18n
                estimated_deposit_date
                date={i18n.l("date.formats.short", transaction.completed_at)}
              />
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {i18n.l("date.formats.short", transaction.completed_at)}
            </Typography>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        md={1}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {transaction.pdf_confirmation && (
          <Tooltip
            title={i18n.t("download_bank_statement")}
            sx={{ marginLeft: "auto" }}
          >
            <IconButton
              target="_blank"
              href={routes.confirmation_letter({
                id: transaction.pdf_confirmation.identifier,
                format: "pdf",
              })}
            >
              <SvgIcon>
                <DocumentArrowDownIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
        {transaction.status == "planned" && state != "past" && (
          <Tooltip title={i18n.t("suspend")} sx={{ marginLeft: "auto" }}>
            <IconButton onClick={toggleOpen}>
              <SvgIcon>
                <ArchiveBoxXMarkIcon color="gray" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
        {transaction.status == "canceled" && state != "past" && (
          <Tooltip title={i18n.t("undo")} sx={{ marginLeft: "auto" }}>
            <IconButton onClick={toggleOpen}>
              <SvgIcon>
                <ArrowUturnUpIcon color="gray" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
        {onClick && (
          <Tooltip title={i18n.t("edit")} sx={{ marginLeft: "auto" }}>
            <IconButton onClick={onClick}>
              <SvgIcon>
                <EllipsisVerticalIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
        {transaction.status == "planned" && state != "past" && (
          <Confirm
            open={open}
            onClose={toggleOpen}
            title={i18n.t("suspend_transaction")}
            actions={[
              {
                label: i18n.t("cancel"),
                onClick: toggleOpen,
              },
              {
                href: routes.transaction({
                  id: transaction.id,
                  state: "canceled",
                  currency,
                }),
                method: "put",
                variant: "contained",
                color: "error",
                label: i18n.t("suspend"),
              },
            ]}
          />
        )}
        {transaction.status == "canceled" && state != "past" && (
          <Confirm
            open={open}
            onClose={toggleOpen}
            title={i18n.t("undo_suspend_transaction")}
            actions={[
              {
                label: i18n.t("cancel"),
                onClick: toggleOpen,
              },
              {
                href: routes.transaction({
                  id: transaction.id,
                  state: "planned",
                  currency,
                }),
                method: "put",
                variant: "contained",
                color: "error",
                label: i18n.t("undo"),
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
}
