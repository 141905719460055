import React, { useState } from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

export default function ({ flash }) {
  const [email, setEmail] = useState("");

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form action={routes.user_confirmation()} method="post">
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n resend_confirmation_instructions />
          </Typography>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("email_address")}
            name="user[email]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus={true}
            autoComplete="email"
          />
          <SubmitButton type="submit" variant="contained" size="large">
            <I18n resend />
          </SubmitButton>
          <Button href={routes.new_user_registration()} fullWidth>
            <I18n sign_up />
          </Button>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
