import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import SimpleBar from "simplebar-react";
import styled from "@mui/material/styles/styled";
import { SideNavItem } from "./SideNavItem";
import routes from "../../utils/routes";
import logoImage from "images/cashwise-white-logo-transparent.png";
import logoTextImage from "images/cashwise-text-logo.png";

const Scrollbar = styled(SimpleBar)``;

export const SideNavigation = ({
  open,
  onClose,
  menu,
  adminMenu,
  children,
}) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const content = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400",
        },
        "& .simplebar-placeholder": {
          height: "0!important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            component="a"
            href={routes.dashboard()}
            sx={{
              ml: 2,
              display: "inline-flex",
              height: 32,
              alignItems: "center",
            }}
          >
            <img src={logoImage} height="32" />
            <img src={logoTextImage} height="12" style={{ marginLeft: 12 }} />
          </Box>
          {children}
        </Box>
        <Divider sx={{ borderColor: "neutral.700" }} />
        <Box
          component="nav"
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {menu.map((item) => (
              <SideNavItem key={item.title} {...item} />
            ))}
          </Stack>
        </Box>
        {adminMenu && (
          <>
            <Divider sx={{ borderColor: "neutral.700" }} />
            <Box
              component="nav"
              sx={{
                px: 2,
                py: 3,
              }}
            >
              <Stack
                component="ul"
                spacing={0.5}
                sx={{
                  listStyle: "none",
                  p: 0,
                  m: 0,
                }}
              >
                {adminMenu.map((item) => (
                  <SideNavItem key={item.title} {...item} />
                ))}
              </Stack>
            </Box>
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
      </Box>
    </Scrollbar>
  );

  return (
    <Drawer
      anchor="left"
      open={mdUp ? true : open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      variant={mdUp ? "permanent" : "temporary"}
    >
      {content}
    </Drawer>
  );
};
