import styled from "@mui/material/styles/styled";

const SIDE_NAV_WIDTH = 280;

export const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("md")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

export const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});
