import * as yup from "yup";
import i18n from "../../../utils/i18n";

const MIN_TRANSFER_AMOUNT = {
  SGD: 100,
  USD: 10000,
};

export default function (currency) {
  return yup.object({
    min_balance: yup
      .number()
      .positive(i18n.t("this_field_must_be_positive"))
      .required(
        i18n.t("field_cannot_be_blank", {
          field: i18n.t("minimum_daily_balance_to_be_maintained"),
        }),
      ),
    min_transfer: yup
      .number()
      .positive(i18n.t("this_field_must_be_positive"))
      .moreThan(
        MIN_TRANSFER_AMOUNT[currency] - 1,
        i18n.t("min_transfer_amount_must_be_greater", {
          amount: MIN_TRANSFER_AMOUNT[currency],
        }),
      )
      .required(
        i18n.t("field_cannot_be_blank", {
          field: i18n.t("minimum_transfer_amount_between_accounts"),
        }),
      ),
    threshold_balance: yup
      .number()
      .min(0, i18n.t("this_field_must_be_positive"))
      .lessThan(
        yup.ref("min_balance"),
        i18n.t("this_field_must_be_less_than_min_balance"),
      )
      .required(
        i18n.t("field_cannot_be_blank", {
          field: i18n.t("threshold_alert_if_balance_below"),
        }),
      ),
  });
}
