import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import SvgIcon from "@mui/material/SvgIcon";
import I18n from "../common/I18n";
import CashflowItem from "./CashflowItem";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import ShieldExclamationIcon from "@heroicons/react/24/solid/ShieldExclamationIcon";

export default function ({ item, openCashflowForm }) {
  const { bank_account, notification, cashflow_items } = item;

  return (
    <Grid
      container
      spacing={0}
      sx={{
        p: 2,
        alignItems: "start",
        borderRadius: 0,
        "&:not(:last-child)": { borderBottom: "solid 0.5px #eee" },
      }}
    >
      <Grid item xs={2} md={2}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: 12,
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{ width: 28, height: 28, mr: 1 }}
            src={bank_account.image}
          />
          <Typography
            variant="body2"
            sx={{ display: { xs: "none", md: "block", whiteSpace: "nowrap" } }}
          >
            {bank_account.short_name}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={10} md={10}>
        <Typography variant="body1">
          <SvgIcon
            color={notification.priority == "critical" ? "error" : "warning"}
            sx={{ mr: 1, verticalAlign: "middle" }}
          >
            {notification.priority == "critical" ? (
              <ExclamationTriangleIcon />
            ) : (
              <ShieldExclamationIcon />
            )}
          </SvgIcon>
          {notification.message}
        </Typography>
        {cashflow_items?.length > 0 && (
          <Stack space={0} sx={{ mt: 1 }}>
            <Typography variant="subtitle2">
              <I18n this_may_affect />
            </Typography>
            <Divider sx={{ mt: 1 }} />
            {cashflow_items.map((_item, i) => (
              <CashflowItem
                key={i}
                item={_item}
                onClick={() => openCashflowForm(_item)}
              />
            ))}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}
