import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CalendarDaysIcon from "@heroicons/react/24/solid/CalendarDaysIcon";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import TransactionRecord from "./TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import { Typography } from "@mui/material";

function ShowDrawerContent({
  currency,
  fx_rates,
  current_user,
  upcomingItems,
  openCashflowForm,
  openTransactionForm,
}) {
  return (
    <Card>
      <CardContent>
        {upcomingItems.map(
          (item, i) =>
            (item.date && (
              <CashflowItem
                key={i}
                item={item}
                fx_rates={fx_rates}
                currency={currency}
                onClick={() => openCashflowForm(item)}
              />
            )) || (
              <TransactionRecord
                key={i}
                transaction={item}
                currency={currency}
                readOnly={current_user.role_value == "read_only"}
                fx_rates={fx_rates}
                onClick={
                  item.status == "placed"
                    ? () => openTransactionForm(item)
                    : undefined
                }
              />
            ),
        )}
        {upcomingItems.length == 0 && <I18n no_scheduled_automation />}
      </CardContent>
    </Card>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("projected_transactions")}
      icon={CalendarDaysIcon}
    >
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        <I18n projected_transactions_subtitle />
        &nbsp;
        <I18n projected_transactions_subtitle_2 />
      </Typography>
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
