import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import i18n from "../../utils/i18n";
import I18n from "../common/I18n";
import TransactionRecord from "../transactions/TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";

function ShowDrawerContent({
  bank_account,
  currency,
  fx_rates,
  current_user,
  pastItems,
  openCashflowForm,
  openTransactionForm,
}) {
  console.log(bank_account);
  return (
    <Stack spacing={2}>
      {bank_account.bank_type == "transactional" &&
        !bank_account.profile.capabilities?.includes("get_balance") && (
          <Card>
            <CardContent
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="subtitle2" sx={{ fontSize: "80%", mr: 1 }}>
                <I18n
                  chrome_extension_balance_update_subtitle
                  name={bank_account.short_name}
                />
                <br />
                <a
                  href={i18n.t("chrome_extension_url")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <I18n chrome_extension_install />
                </a>
              </Typography>
              <Button
                variant="contained"
                href={bank_account.bank.description}
                target="_blank"
              >
                <I18n login_to name={bank_account.short_name} />
              </Button>
            </CardContent>
          </Card>
        )}
      <Card>
        <CardContent>
          {pastItems.map(
            (item, i) =>
              (item.date && (
                <CashflowItem
                  key={i}
                  item={item}
                  onClick={() => openCashflowForm(item)}
                />
              )) || (
                <TransactionRecord
                  key={i}
                  transaction={item}
                  currency={currency}
                  readOnly={current_user.role_value == "read_only"}
                  fx_rates={fx_rates}
                  onClick={
                    item.status == "placed"
                      ? () => openTransactionForm(item)
                      : undefined
                  }
                />
              ),
          )}
          {pastItems.length == 0 && <I18n no_automation />}
        </CardContent>
      </Card>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, bank_account } = props;

  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={bank_account.name}
      avatar={bank_account.image}
    >
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
