import React from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import SvgIcon from "@mui/material/SvgIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import Divider from "@mui/material/Divider";

export default function ({ open, onClose, title, children }) {
  return (
    <Modal open={open} onClose={onClose} disablePortal>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 640,
          boxShadow: 24,
        }}
      >
        <Card sx={{ p: 2 }}>
          <CardHeader
            title={title}
            action={
              <IconButton onClick={onClose}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            }
          />
          <Divider />
          <CardContent>{children}</CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
