import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { UserApplicationLayout } from "../layouts/UserApplicationLayout";
import EditDrawer from "./EditDrawer";
import Form from "../common/Form";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";

export default function ({ ...props }) {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onClick = () => setOpen(true);
  const { current_user } = props;

  return (
    <UserApplicationLayout {...props}>
      <Stack spacing={2}>
        <Card>
          <CardHeader
            title={i18n.t("user_profile")}
            action={
              <Button onClick={onClick} variant="contained">
                <I18n edit />
              </Button>
            }
          />
          <Divider />
          <CardContent sx={{ py: 2 }}>
            <Typography variant="h6" sx={{ my: 2 }}>
              <I18n user_name />
            </Typography>
            <Typography variant="body1" sx={{ my: 2 }}>
              {current_user.name}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" sx={{ my: 2 }}>
              <I18n email_address />
            </Typography>
            <Typography variant="monospace" sx={{ mt: 2 }}>
              {current_user.email}
            </Typography>
            {!current_user.email_verified && (
              <Chip
                label={i18n.t("not_verified")}
                size="small"
                color="warning"
                sx={{ ml: 2 }}
              />
            )}
            {!current_user.email_verified && (
              <Form action={routes.user()} method="put">
                <input
                  type="hidden"
                  name="send_email_verification"
                  value="true"
                />
                <Button variant="outlined" sx={{ mr: 1 }} type="submit">
                  <I18n verify_email />
                </Button>
              </Form>
            )}
            {current_user.profile?.phone_number && <Divider sx={{ my: 2 }} />}
            {current_user.profile?.phone_number && (
              <>
                <Typography variant="h6" sx={{ my: 2 }}>
                  <I18n phone_number />
                </Typography>
                <Typography variant="body1" sx={{ my: 2 }}>
                  {current_user.profile?.phone_number}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={i18n.t("avatar")}
            action={
              <Button onClick={onClick} variant="contained">
                <I18n edit />
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <img
              src={current_user.image}
              style={{ borderRadius: 60, width: 120 }}
            />
          </CardContent>
        </Card>
      </Stack>
      <EditDrawer open={open} onClose={onClose} {...props} />
    </UserApplicationLayout>
  );
}
