import React, { createRef } from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { UserApplicationLayout } from "../../layouts/UserApplicationLayout";
import { Form, submitForm } from "../../common/Form";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const SubForm = React.forwardRef(({ notification_settings }, ref) => {
  return (
    <Form action={routes.user_notification()} method="put" ref={ref}>
      <input
        type="hidden"
        name="notification[email][critical]"
        value={notification_settings.email?.critical}
      />
      <input
        type="hidden"
        name="notification[email][warning]"
        value={notification_settings.email?.warning}
      />
      <input
        type="hidden"
        name="notification[email][informational]"
        value={notification_settings.email?.informational}
      />
      <input
        type="hidden"
        name="notification[sms][critical]"
        value={notification_settings.sms?.critical}
      />
      <input
        type="hidden"
        name="notification[sms][warning]"
        value={notification_settings.sms?.warning}
      />
      <input
        type="hidden"
        name="notification[sms][informational]"
        value={notification_settings.sms?.informational}
      />
    </Form>
  );
});

export default function ({ notification_settings, ...props }) {
  const formRef = createRef();
  const onChangeNotification = (media, level, e) => {
    formRef.current.querySelector(
      `input[name="notification[${media}][${level}]"]`,
    ).value = e.target.checked;
    submitForm(formRef.current);
  };

  return (
    <UserApplicationLayout {...props}>
      <Stack spacing={2}>
        <Card>
          <CardHeader title={i18n.t("notification_to_email")} />
          <Divider />
          <CardContent>
            <FormGroup sx={{ px: 4 }}>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={notification_settings.email?.critical}
                    onChange={(e) =>
                      onChangeNotification("email", "critical", e)
                    }
                  />
                }
                label={i18n.t("critical")}
              />
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={notification_settings.email?.warning}
                    onChange={(e) =>
                      onChangeNotification("email", "warning", e)
                    }
                  />
                }
                label={i18n.t("warning")}
              />
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={notification_settings.email?.informational}
                    onChange={(e) =>
                      onChangeNotification("email", "informational", e)
                    }
                  />
                }
                label={i18n.t("informational")}
              />
            </FormGroup>
          </CardContent>
        </Card>
        {null /* SMS messaging is not supported yet */ && (
          <Card>
            <CardHeader title={i18n.t("notification_to_sms")} />
            <Divider />
            <CardContent>
              <FormGroup sx={{ px: 4 }}>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={notification_settings.sms.critical}
                      onChange={(e) =>
                        onChangeNotification("sms", "critical", e)
                      }
                    />
                  }
                  label={i18n.t("critical")}
                />
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={notification_settings.sms.warning}
                      onChange={(e) =>
                        onChangeNotification("sms", "warning", e)
                      }
                    />
                  }
                  label={i18n.t("warning")}
                />
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={notification_settings.sms.informational}
                      onChange={(e) =>
                        onChangeNotification("sms", "informational", e)
                      }
                    />
                  }
                  label={i18n.t("informational")}
                />
              </FormGroup>
            </CardContent>
          </Card>
        )}
        <SubForm ref={formRef} notification_settings={notification_settings} />
      </Stack>
    </UserApplicationLayout>
  );
}
