import axios from "axios";
import routes from "./routes";

async function api({
  url,
  method,
  headers,
  data,
  onStart,
  onSuccess,
  onError,
}) {
  try {
    if (onStart) onStart();
    const response = await axios.request({
      headers,
      url,
      method,
      data: method == "get" ? undefined : data,
      params: method == "get" ? data : undefined,
    });
    const responseURL = response.request?.responseURL.replace(/\?.*$/, "");
    // redirected to login page
    if (responseURL.endsWith(routes.new_user_session())) location.reload();
    else if (onSuccess) onSuccess(response.data);
  } catch (error) {
    if (onError) onError(error.message);
  }
}

export default api;
