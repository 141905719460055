import React from "react";
import { ConsoleApplicationLayout } from "../../layouts/ConsoleApplicationLayout";
import Stack from "@mui/material/Stack";
import Account from "./Account";
import ShowDrawer from "./ShowDrawer";

export default function ({ current_user, accounts }) {
  const [openAccount, setOpenAccount] = React.useState(false);
  const closeAccount = () => setOpenAccount(false);

  return (
    <ConsoleApplicationLayout current_user={current_user}>
      <h1>Accounts</h1>
      <Stack spacing={2}>
        {accounts.map((account, i) => (
          <Account
            key={i}
            account={account}
            onClick={() => setOpenAccount(account)}
          />
        ))}
      </Stack>
      <ShowDrawer
        open={openAccount}
        onClose={closeAccount}
        account={openAccount}
      />
    </ConsoleApplicationLayout>
  );
}
