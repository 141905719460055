import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

export default function ({
  app,
  select,
  description,
  onSelectApp,
  action,
  disabled,
  children,
}) {
  return (
    <Card
      onClick={onSelectApp && (() => onSelectApp(app))}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        ...(disabled ? { opacity: 0.7 } : {}),
        ...(onSelectApp
          ? {
              cursor: "pointer",
              "&:hover": { border: 0.5, borderColor: "#6366F1" },
            }
          : {}),
        ...(app.id == select?.id || select === true
          ? { border: 2, borderColor: "#6366F1" }
          : {}),
      }}
    >
      <Avatar
        src={app.image}
        variant="rounded"
        sx={{ height: 56, width: 56, alignSelf: "flex-start" }}
      />
      <div style={{ marginLeft: 24, marginRight: "auto" }}>
        <Typography variant="h6" sx={{ display: "block" }}>
          {app.name}
        </Typography>
        {children && <div style={{ paddingTop: 4 }}>{children}</div>}
        {!children && description && (
          <Typography variant="subtitle2">{app.description}</Typography>
        )}
      </div>
      {action && <div style={{ alignSelf: "flex-start" }}>{action}</div>}
    </Card>
  );
}
