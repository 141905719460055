import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  fx_safety_margin: yup
    .number()
    .positive(i18n.t("this_field_must_be_positive"))
    .required(
      i18n.t("field_cannot_be_blank", {
        field: i18n.t("fx_safety_margin"),
      }),
    ),
});

function NewDrawerContent({ account }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fx_safety_margin: account.profile?.fx_safety_margin * 100 || 0,
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <Form
      action={routes.account_currencies()}
      onSubmit={(event) =>
        handleSubmit(
          () => undefined,
          () => event.preventDefault(),
        )()
      }
      method="post"
    >
      <Card>
        <CardHeader title={i18n.t("fx_safety_margin")} />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("fx_safety_margin") + "(%):"}
            helperText={errors.trailing_stop_rate?.message}
            {...register("fx_safety_margin")}
            error={"fx_safety_margin" in errors}
            autoFocus
          />
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            <I18n fx_safety_margin_detail_subtitle />
          </Typography>
        </CardContent>
      </Card>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
        <SubmitButton fullWidth variant="contained" type="submit">
          <I18n update />
        </SubmitButton>
      </Box>
    </Form>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("fx_payout")}
      icon={PencilIcon}
    >
      <NewDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
