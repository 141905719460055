import React from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  email: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("email_address") }),
    )
    .email(i18n.t("field_is_incorrect", { field: i18n.t("email_address") })),
});

export default function ({ flash }) {
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form action={routes.user_password()} method="post">
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n forgot_password />
          </Typography>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("email_address")}
            helperText={errors.email?.message}
            error={"email" in errors}
            {...register("email")}
            autoFocus={true}
            autoComplete="email"
          />
          <SubmitButton type="submit" variant="contained" size="large">
            <I18n send_reset_password_instructions />
          </SubmitButton>
          <Button
            href={routes.new_user_session()}
            fullWidth
            sx={{ marginY: 1 }}
          >
            <I18n sign_in />
          </Button>
          <Button
            href={routes.new_user_registration()}
            fullWidth
            sx={{ marginY: 1 }}
          >
            <I18n sign_up />
          </Button>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
