import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import Dropdown from "../../common/Dropdown";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import api from "../../../utils/api";

function NewDrawerContent({ current_user, user, excludeOwner }) {
  const ROLES = i18n
    .t("roles")
    .filter((role) => (excludeOwner ? role.name != "Owner" : true));
  const [role, setRole] = useState(
    ROLES.find((role) => role.name == user.role).value,
  );
  const [audits, setAudits] = useState([]);
  const retrieveAudits = () => {
    api({
      url: routes.retrieve_audits_account_user({ id: user.id }),
      method: "get",
      onStart: () => undefined,
      onError: () => undefined,
      onSuccess: (response) => setAudits(response),
    });
  };
  useEffect(retrieveAudits, []);

  return (
    <Stack spacing={2}>
      <Form action={routes.account_user({ id: user.id })} method="put">
        <Card>
          <CardHeader title={i18n.t("user_role")} />
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              <Dropdown
                fullWidth
                readOnly={current_user.id == user.id}
                values={ROLES}
                variant="standard"
                label={i18n.t("role")}
                onChange={(e) => setRole(e.target.value)}
                value={role}
                name="role"
              />
            </Stack>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton
              variant="contained"
              type="submit"
              disabled={current_user.id == user.id}
            >
              <I18n update />
            </SubmitButton>
          </CardActions>
        </Card>
      </Form>
      <Typography variant="h6" sx={{ fontSize: "75%", pl: 2, pt: 2 }}>
        <I18n login_audits />
      </Typography>
      <Timeline>
        {audits.map((audit, i) => (
          <TimelineItem
            key={i}
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            <TimelineOppositeContent color="textSecondary">
              <Typography variant="subtitle2" sx={{ fontSize: "75%" }}>
                {i18n.toTime("time.formats.short", Date.parse(audit.action_at))}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              {audits.length - 1 > i && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" sx={{ fontSize: "75%" }}>
                {audit.comment}
                {audit.remote_address && (
                  <>&nbsp; (IP: {audit.remote_address})</>
                )}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
        {audits.length == 0 && (
          <Typography variant="subtitle2" sx={{ p: 2 }}>
            <I18n no_login_audits />
          </Typography>
        )}
      </Timeline>
    </Stack>
  );
}

export default function (props) {
  const { user, open, onClose } = props;
  return (
    <RightDrawerLayout
      open={!!open}
      onClose={onClose}
      title={user.name}
      avatar={user.image}
      avatarVariant="circle"
    >
      <NewDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
