import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import CurrencyCard from "./CurrencyCard";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  trailing_stop_rate: yup
    .number()
    .positive(i18n.t("this_field_must_be_positive"))
    .required(
      i18n.t("field_cannot_be_blank", {
        field: i18n.t("drop_rate"),
      }),
    ),
});

function EditDrawerContent({ bank_account, currency_pair }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enable_trailing_stop: bank_account.profile.enable_trailing_stop,
      trailing_stop_rate: bank_account.profile.trailing_stop_rate,
    },
    resolver: yupResolver(SCHEMA),
  });
  const enable_trailing_stop = watch("enable_trailing_stop");
  const trailing_stop_rate = watch("trailing_stop_rate");

  return (
    <Form
      action={routes.account_currency({ id: bank_account.id })}
      onSubmit={(event) =>
        handleSubmit(
          () => undefined,
          () => event.preventDefault(),
        )()
      }
      method="put"
    >
      <Card>
        <CardHeader
          title={
            <CurrencyCard
              bank_account={bank_account}
              currency_pair={currency_pair}
            />
          }
        />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="enable_trailing_stop"
                    checked={enable_trailing_stop}
                    onChange={() =>
                      setValue("enable_trailing_stop", !enable_trailing_stop)
                    }
                  />
                }
                label={i18n.t("trailing_stop")}
              />
            </FormGroup>
            {enable_trailing_stop && (
              <TextField
                fullWidth
                required
                variant="standard"
                label={i18n.t("drop_rate") + "(%):"}
                helperText={errors.trailing_stop_rate?.message}
                {...register("trailing_stop_rate")}
                error={"trailing_stop_rate" in errors}
                autoFocus
              />
            )}
            {enable_trailing_stop && (
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                <I18n trailing_stop_subtitle rate={trailing_stop_rate} />
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
        <SubmitButton fullWidth variant="contained" type="submit">
          <I18n update />
        </SubmitButton>
      </Box>
    </Form>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("fx_transaction")}
      icon={PencilIcon}
    >
      <EditDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
