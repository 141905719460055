import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import BellIcon from "@heroicons/react/24/solid/BellIcon";
import ShieldExclamationIcon from "@heroicons/react/24/solid/ShieldExclamationIcon";
import ExclamationCircleIcon from "@heroicons/react/24/solid/ExclamationCircleIcon";
import InformationCircleIcon from "@heroicons/react/24/solid/InformationCircleIcon";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";

function NotificationContent({ notifications }) {
  return (
    <Stack spacing={2}>
      {notifications.length == 0 && (
        <Typography variant="body1">
          <I18n no_notifications />
        </Typography>
      )}
      {notifications.map((notification) => (
        <Card
          key={notification.id}
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <SvgIcon>
            {notification.priority == "informational" && (
              <InformationCircleIcon color="green" />
            )}
            {notification.priority == "warning" && (
              <ExclamationCircleIcon color="yellow" />
            )}
            {notification.priority == "critical" && (
              <ShieldExclamationIcon color="red" />
            )}
          </SvgIcon>
          <div style={{ marginLeft: 16 }}>
            <Typography variant="body1">
              {notification.message}
              <br />
            </Typography>
            <Typography variant="subtitle2">
              <I18n l="date.long" v={notification.created_at} />
            </Typography>
          </div>
        </Card>
      ))}
    </Stack>
  );
}

export function NotificationDrawer(props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("notifications")}
      icon={BellIcon}
    >
      <NotificationContent {...props} />
    </RightDrawerLayout>
  );
}
