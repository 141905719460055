import React from "react";
import QuestionMarkCircleIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";

export default function ({ title, flex, children }) {
  return (
    <Tooltip title={title}>
      <div style={flex ? { display: "flex", alignItems: "center" } : {}}>
        {children}
        <SvgIcon
          size="small"
          color="action"
          sx={{ position: "relative", top: 4 }}
        >
          <QuestionMarkCircleIcon />
        </SvgIcon>
      </div>
    </Tooltip>
  );
}
