import React from "react";
import i18n from "../../utils/i18n";
import currencyName, {
  numberToCurrencyNoUnit,
} from "../../utils/currency-name";

const CLICK_HERE_TAG = "%%CLICK_HERE%%";

export default function ({ t, l, v, ...params }) {
  const keys = Object.keys(params).filter((k) => params[k] === true);
  if (!l && (t || keys.length == 1)) {
    delete params[keys[0]];
    const click_here_link = params.click_here;
    params.click_here = CLICK_HERE_TAG;

    const regex = /\*([^*]*)\*/g;
    const text = i18n.t(t || keys[0], params);
    let match;
    let index = 0;
    let html = [];
    let key = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!(match = regex.exec(text))) {
        match = [text];
        match.index = text.length;
      }
      const text_fragment = text.slice(index, match.index);
      const click_here_match = text_fragment.match(CLICK_HERE_TAG);
      if (click_here_match) {
        html.push(
          <React.Fragment key={key}>
            {text_fragment.slice(0, click_here_match.index)}
            <a
              href={typeof click_here_link == "string" ? click_here_link : "#"}
              onClick={
                typeof click_here_link == "function"
                  ? click_here_link
                  : undefined
              }
            >
              {i18n.t("click_here")}
            </a>
            {text_fragment.slice(
              click_here_match.index + click_here_match[0].length,
              text_fragment.length,
            )}
          </React.Fragment>,
        );
      } else
        html.push(<React.Fragment key={key}>{text_fragment}</React.Fragment>);
      if (match.length == 1) break;
      key += 1;
      html.push(<b key={key}>{match[1]}</b>);
      key += 1;
      index = match.index + match[0].length;
    }
    return <>{html}</>;
  }
  if (l) {
    switch (l) {
      case "currency":
        if (v == null || typeof v == "undefined") return <>&mdash;</>;
        if (params.noUnit)
          return <>{numberToCurrencyNoUnit(v, params.currency)}</>;
        return (
          <>
            {currencyName(params.currency)}
            &nbsp;
            <b>{numberToCurrencyNoUnit(v, params.currency)}</b>
          </>
        );
      case "number":
        if (v == null || typeof v == "undefined") return <>&mdash;</>;
        return <>{i18n.numberToRounded(v, params)}</>;
      case "percentage":
        if (v == null || typeof v == "undefined") return <>&mdash;</>;
        return <>{i18n.numberToPercentage(v, params)}</>;
      case "date.short":
      case "date.long":
      case "date.default":
      case "time.short":
      case "time.long":
      case "time.default":
        return <>{i18n.toTime(l.replace(".", ".formats."), v, params)}</>;
    }
  }
}
