import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";

export default function ({ error, flash }) {
  window.onbeforeunload = window.opener.handleAccountIntegrationCancel;

  return (
    <PopupApplicationLayout title="account_integration" flash={flash}>
      <Stack spacing={2}>
        {(!error && (
          <Typography variant="body1">
            <I18n
              model_updated_successfully
              model={i18n.t("account_authentication_integration")}
            />
            <br />
            <I18n thank_you />
          </Typography>
        )) || (
          <Typography variant="body1">
            <I18n t={`account_integration_error_${error}`} />
          </Typography>
        )}
        <div />
        <Button fullWidth variant="contained" onClick={window.close}>
          <I18n close />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}
