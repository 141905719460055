import React, { useState } from "react";
import { ConsoleApplicationLayout } from "../../layouts/ConsoleApplicationLayout";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MinusCircleIcon from "@heroicons/react/24/solid/MinusCircleIcon";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import Confirm from "../../common/Confirm";
import routes from "../../../utils/routes";
import { SvgIcon } from "@mui/material";

function User({ user, current_user, onClick }) {
  const disabled = user.id === current_user.id;

  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 1 }}>
      <Avatar src={user.image} sx={{ width: 36, height: 36, mr: 2 }} />
      <Typography variant="body1">{user.name}</Typography>
      <IconButton
        onClick={onClick}
        sx={{ marginLeft: "auto" }}
        disabled={disabled}
      >
        <SvgIcon>
          {user.console_role ? <MinusCircleIcon /> : <PlusCircleIcon />}
        </SvgIcon>
      </IconButton>
    </Card>
  );
}

export default function ({ current_user, users }) {
  const [openAddRole, setAddRole] = useState(false);
  const [openDeleteRole, setDeleteRole] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const toggleAddRole = () => setAddRole(!openAddRole);
  const toggleDeleteRole = () => setDeleteRole(!openDeleteRole);
  const onClick = (user) => {
    setSelectedUser(user);
    if (user.console_role) {
      toggleDeleteRole();
    } else {
      toggleAddRole();
    }
  };
  const admin_users = users.filter((user) => user.console_role);
  const non_admin_users = users.filter((user) => !user.console_role);

  return (
    <ConsoleApplicationLayout current_user={current_user}>
      <h1>Admin Users</h1>
      <Stack spacing={2}>
        {admin_users.length > 0 && (
          <Typography variant="h3">Admin Users</Typography>
        )}
        {admin_users.map((user) => (
          <User
            key={user.id}
            user={user}
            current_user={current_user}
            onClick={() => onClick(user)}
          />
        ))}
        {non_admin_users.length > 0 && <Divider />}
        {non_admin_users.length > 0 && (
          <Typography variant="h3">Non-Admin Users</Typography>
        )}
        {non_admin_users.map((user) => (
          <User
            key={user.id}
            user={user}
            current_user={current_user}
            onClick={() => onClick(user)}
          />
        ))}
      </Stack>
      <Confirm
        open={openAddRole}
        onClose={toggleAddRole}
        title="Add Admin Role"
        actions={[
          {
            label: "OK",
            href: routes.console_users({ user_id: selectedUser?.id }),
            method: "POST",
            variant: "contained",
          },
          { label: "Cancel", onClick: toggleAddRole },
        ]}
      ></Confirm>
      <Confirm
        open={openDeleteRole}
        onClose={toggleDeleteRole}
        title="Delete Admin Role"
        actions={[
          {
            label: "OK",
            href: routes.console_user({ id: selectedUser?.id }),
            method: "DELETE",
            variant: "contained",
          },
          { label: "Cancel", onClick: toggleDeleteRole },
        ]}
      ></Confirm>
    </ConsoleApplicationLayout>
  );
}
