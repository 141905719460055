import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";

const TIMER = 250;

function RefreshButton({ onClick, loading, sx, title, href }) {
  const [delayedLoading, setDelayedLoading] = useState(loading);
  useEffect(() => {
    if (loading == true) setDelayedLoading(true);
    else setTimeout(() => setDelayedLoading(false), TIMER);
  }, [loading]);

  return (
    <Tooltip title={title}>
      <IconButton
        sx={sx}
        onClick={onClick}
        disabled={delayedLoading}
        href={href}
      >
        {(delayedLoading && <CircularProgress color="inherit" size={24} />) || (
          <SvgIcon>
            <ArrowPathIcon />
          </SvgIcon>
        )}
      </IconButton>
    </Tooltip>
  );
}

export default RefreshButton;
