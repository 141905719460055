import i18n from "./i18n";

export const CURRENCIES = i18n
  .t("currencies")
  .reduce((hash, item) => Object.assign(hash, { [item.value]: item }), {});

export const numberToCurrency = (number, currency, options) =>
  i18n.numberToCurrency(
    number,
    Object.assign(
      {
        delimiter: ",",
        precision: CURRENCIES[currency].precision,
        unit: CURRENCIES[currency].name,
        format: "%u %n",
        negativeFormat: "%u -%n",
      },
      options,
    ),
  );

export const numberToCurrencyNoUnit = (number, currency) =>
  i18n.numberToCurrency(number, {
    delimiter: ",",
    precision: CURRENCIES[currency].precision,
    unit: "",
  });

export default (currency) => CURRENCIES[currency].name;
