import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ConsoleApplicationLayout } from "../../layouts/ConsoleApplicationLayout";
import Account from "../accounts/Account";
import I18n from "../../common/I18n";

function AccountCard({ n_accounts, recent_accounts }) {
  return (
    <Card>
      <CardHeader title="Accounts" />
      <Divider />
      <CardContent>
        <div>Number of Enabled Accounts: {n_accounts}</div>
        <h3>Recent Sign-ups</h3>
        {recent_accounts.map((account) => (
          <Account key={account.id} account={account} />
        ))}
      </CardContent>
    </Card>
  );
}

function AUMCard({ bank_total_amount }) {
  return (
    <Card>
      <CardHeader title="Assets Under Management" />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          {bank_total_amount.map((item) => (
            <Card
              key={item.bank.id}
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  variant="rounded"
                  src={item.bank.image}
                  sx={{ width: 36, height: 36, mr: 2 }}
                />
                <Typography variant="body2">
                  {item.bank.name}
                  <br />
                  {item.currency} account
                </Typography>
              </div>
              <div>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <I18n l="currency" v={item.amount} currency={item.currency} />
                </Typography>
              </div>
            </Card>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default function ({
  current_user,
  recent_accounts,
  n_accounts,
  bank_total_amount,
}) {
  return (
    <ConsoleApplicationLayout current_user={current_user}>
      <h1>Dashboard</h1>
      <Stack spacing={2}>
        <AccountCard
          n_accounts={n_accounts}
          recent_accounts={recent_accounts}
        />
        <AUMCard bank_total_amount={bank_total_amount} />
      </Stack>
    </ConsoleApplicationLayout>
  );
}
