import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import routes from "../../utils/routes";
import BankIcons from "./BankIcons";

const AIRWALLEX_REFERAL_URL = "https://partners.airwallex.com/cashwise";
const WISE_REFERAL_URL = "https://wise.com/invite/dic/maadriant";
const SYFE_REFERAL_URL = "https://tally.so/r/mYGEMv";
// https://www.syfe.com/syfe-for-business

const style_ListItem = {
  borderRadius: 1,
  marginTop: 1,
  border: 0.5,
  borderColor: "#6366F1",
  "&:hover": { border: 2 },
};

export default function () {
  return (
    <Card>
      <CardHeader title="Getting Started with CashWise" />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="body1">
            Thank you for registering with CashWise.
            <br />
            There are 3 simple steps to get going...
          </Typography>
          <Stepper activeStep={0} orientation="vertical">
            <Step expanded>
              <StepLabel>Open accounts</StepLabel>
              <StepContent>
                Click to open new accounts if you don&apos;t already have them.
                At a minimum, you need to have a Syfe account:
                <List sx={{ display: "flex", gap: 1 }}>
                  <ListItem
                    component="a"
                    href={AIRWALLEX_REFERAL_URL}
                    target="_blank"
                    sx={style_ListItem}
                  >
                    <ListItemAvatar sx={{ alignSelf: "flex-start", mt: 2 }}>
                      <Avatar variant="rounded" src={BankIcons.AirWallex} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="body1">
                        <b>AirWallex</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        Equivalent to your bank, you can make payments, receive
                        money and perform FX transfers.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    component="a"
                    href={WISE_REFERAL_URL}
                    target="_blank"
                    sx={style_ListItem}
                  >
                    <ListItemAvatar sx={{ alignSelf: "flex-start", mt: 2 }}>
                      <Avatar variant="rounded" src={BankIcons.Wise} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="body1">
                        <b>Wise</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        Equivalent to your bank, you can make payments, receive
                        money and perform FX transfers.
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    component="a"
                    href={SYFE_REFERAL_URL}
                    target="_blank"
                    sx={style_ListItem}
                  >
                    <ListItemAvatar sx={{ alignSelf: "flex-start", mt: 2 }}>
                      <Avatar variant="rounded" src={BankIcons.Syfe} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography variant="body1">
                        <b>Syfe</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        Your investment account. For treasury use cases,
                        you&apos;ll likely establish Cash+ Flexi (SGD & USD).
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </StepContent>
            </Step>
            <Step expanded>
              <StepLabel>Link your accounts to CashWise</StepLabel>
              <StepContent>
                To enable automation, you&apos;ll need to connect your
                AirWallex, Wise and Syfe accounts to CashWise.
                <br />
                You can access{" "}
                <a href={routes.account_integrations()}>Integration</a> setting
                page under Account. Also set your treasury operational
                parameters for each account there.
              </StepContent>
            </Step>
            <Step expanded>
              <StepLabel>Define Cash Flows</StepLabel>
              <StepContent>
                Add larger cash flow items here, especially if the spending
                amounts are more than 50% of your target balance.
              </StepContent>
            </Step>
          </Stepper>

          <Typography variant="body1">
            If you have any questions, please feel free to contact our customer
            support at any time:
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:support@cashwise.asia">support@cashwise.asia</a>
              </li>
              <li>Chat: access at the bottom left of the screen</li>
            </ul>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
