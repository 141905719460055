import BuildingOffice2Icon from "@heroicons/react/24/solid/BuildingOffice2Icon";
import ArrowTrendingUpIcon from "@heroicons/react/24/solid/ArrowTrendingUpIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import routes from "../../utils/routes";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";

export const ConsoleApplicationMenu = [
  {
    icon: ArrowTrendingUpIcon,
    title: "dashboard",
    route: routes.console_dashboard_index,
  },
  {
    icon: BuildingOffice2Icon,
    title: "accounts",
    route: routes.console_accounts,
  },
  {
    icon: CurrencyDollarIcon,
    title: "transactions",
    route: routes.console_automations,
  },
  {
    icon: UserIcon,
    title: "users",
    route: routes.console_users,
  },
];
