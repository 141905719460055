import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { Chart } from "react-google-charts";
import I18n from "../../common/I18n";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "../../common/Form";
import Popup from "./Popup";
import { AnalysisApplicationLayout } from "../../layouts/AnalysisApplicationLayout";
import { numberToCurrency } from "../../../utils/currency-name";
import routes from "../../../utils/routes";
import api from "../../../utils/api";
import i18n from "../../../utils/i18n";
import SubmitButton from "../../common/SubmitButton";

const INTERVAL = 3000;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function GeneratingReport({ account, progress }) {
  console.log(progress);
  return (
    <Stack spacing={4} sx={{ display: "flex", alignItems: "center", mb: 6 }}>
      <Typography
        variant="h2"
        sx={{ my: 6, fontSize: "1.5rem", textAlign: "center" }}
      >
        <I18n generating_report />
        <br />
        {account.name}
      </Typography>
      <CircularProgressWithLabel value={progress * 10} />
      <div />
      <Typography variant="subtitle2" align="center">
        <I18n please_wait />
      </Typography>
    </Stack>
  );
}

const PHONE_REGX =
  /^$|^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("name") })),
  email: yup
    .string()
    .email(i18n.t("field_is_incorrect", { field: i18n.t("email_address") }))
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("email_address") }),
    ),
  phone: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("phone_number") }),
    )
    .matches(
      PHONE_REGX,
      i18n.t("field_is_incorrect", { field: i18n.t("phone_number") }),
    ),
});

function SendMeDialog({ app_integration, open, onClose }) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: app_integration.data.name,
      email: app_integration.data.email,
      phone: "",
    },
    resolver: yupResolver(SCHEMA),
  });
  const phone = watch("phone");

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={i18n.t("continue_with_contact")}
    >
      <Form
        action={routes.analysis_reports({
          type: "AppIntegration",
          identifier: app_integration.identifier,
        })}
        method="post"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <input
          type="hidden"
          name="identifier"
          value={app_integration.identifier}
        />
        <Stack spacing={4}>
          <TextField
            fullWidth
            required
            variant="outlined"
            label={i18n.t("name")}
            {...register("name")}
            error={"name" in errors}
          />
          <TextField
            fullWidth
            required
            variant="outlined"
            label={i18n.t("email_address")}
            {...register("email")}
            error={"email" in errors}
          />
          <MuiPhoneNumber
            defaultCountry={"sg"}
            required
            fullWidth
            variant="outlined"
            label={i18n.t("phone_number")}
            helperText={errors.phone?.message}
            name="phone"
            value={phone}
            onChange={(value) => setValue("phone", value)}
            error={"phone" in errors}
          />
          <SubmitButton fullWidth variant="contained" type="submit">
            <I18n submit />
          </SubmitButton>
        </Stack>
      </Form>
    </Popup>
  );
}

function Report({ account, results, app_integration, fx_rates }) {
  const [popup, setPopup] = useState(false);
  const data = [["Month", "Interest", "Cumulative"]].concat(
    results.interest_chart.map((item) => [
      { v: new Date(item.month), f: item.month },
      item.profit,
      item.cumurative_profit,
    ]),
  );
  const options = {
    legend: { position: "top", alignment: "center" },
    vAxis: {
      0: { format: "decimal" },
      1: { format: "decimal" },
      minorGridlines: { color: "transparent" },
    },
    hAxis: { title: "", gridlines: { color: "transparent" } },
    seriesType: "bars",
    series: {
      0: {
        type: "bar",
        targetAxisIndex: 0,
      },
      1: {
        type: "area",
        targetAxisIndex: 1,
      },
    },
    chartArea: {
      left: 80,
      top: 24,
      bottom: 24,
      right: 80,
      width: "100%",
      height: "100%",
    },
    colors: ["#4CAF50", "#8BC34A"],
  };

  return (
    <Stack spacing={4} sx={{ mb: 6 }}>
      <Typography variant="h2" sx={{ my: 6, fontSize: "1.5rem" }}>
        <I18n analysis_result />
        <br />
        {account.name}
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ px: { md: 1, xs: 0 }, py: { xs: 1, md: 0 } }}
        >
          <div style={{ padding: 32, background: "#b0f3b3", height: "100%" }}>
            <Typography variant="body2">
              <I18n estimated_annual_interest />
            </Typography>
            <Typography
              variant="h3"
              sx={{ mt: 2, textAlign: "end", fontSize: "110%" }}
            >
              {numberToCurrency(
                Math.round(results.estimated_annual_interest),
                "SGD",
                { precision: 0 },
              )}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ px: { md: 1, xs: 0 }, py: { xs: 1, md: 0 } }}
        >
          <div style={{ padding: 32, background: "#f9f9f9", height: "100%" }}>
            <Typography variant="body2">
              <I18n average_payouts />
            </Typography>
            <Typography
              variant="h3"
              sx={{ mt: 2, textAlign: "end", fontSize: "110%" }}
            >
              <I18n
                l="number"
                v={Math.round(results.average_payouts)}
                precision={0}
              />
              &nbsp;/mo
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Typography variant="h2" sx={{ my: 6, fontSize: "1.5rem" }}>
        <I18n monthly_missing_interest />
      </Typography>
      <Chart
        chartType="ComboChart"
        width="100%"
        height="250px"
        data={data}
        options={options}
      />
      <div />
      <Button variant="contained" onClick={() => setPopup(true)}>
        <I18n send_me_report />
      </Button>
      <Typography variant="subtitle2">
        <I18n
          preparation_date
          date={i18n.toTime("date.formats.long", new Date())}
        />
        <br />
        <I18n analysis_assumptions fx_rate={fx_rates.USD.SGD} />
      </Typography>
      <div />
      <div />
      <SendMeDialog
        open={popup}
        onClose={() => setPopup(false)}
        app_integration={app_integration}
      />
    </Stack>
  );
}

export default function ({ account, app_integration, fx_rates }) {
  const [results, setResults] = useState(undefined);
  const [progress, setProgress] = useState(0);
  let interval;
  const retrieveResults = () => {
    api({
      url: routes.retrieve_results_analysis_reports(),
      method: "get",
      data: { identifier: app_integration.identifier },
      onSuccess: (res) => {
        if (res.completed) {
          setResults(res.results);
          clearInterval(interval);
        } else {
          setProgress(res.progress);
        }
      },
    });
  };
  useEffect(() => {
    interval = setInterval(retrieveResults, INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <AnalysisApplicationLayout title="configure_report" wide={!!results}>
      {!results && <GeneratingReport account={account} progress={progress} />}
      {results && (
        <Report
          account={account}
          results={results}
          app_integration={app_integration}
          fx_rates={fx_rates}
        />
      )}
    </AnalysisApplicationLayout>
  );
}
