import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import BankCard from "./BankCard";
import I18n from "../../common/I18n";
import routes from "../../../utils/routes";

export default function ({ bank, profiles }) {
  const [select, setSelect] = useState();
  const handleAccountIntegrationCancel = () => {
    window.opener.handleAccountIntegrationCancel();
    window.close();
  };

  return (
    <PopupApplicationLayout title="account_integration">
      <Form action={routes.profiles_account_integrations()} method="get">
        <input type="hidden" name="bank_id" value={bank.id} />
        <input type="hidden" name="credentials" value={select?.credentials} />
        <Stack spacing={2}>
          <Typography variant="body1">
            <I18n select_your_profile />
          </Typography>
          <div />
          {profiles.map((profile, i) => (
            <BankCard
              key={i}
              bank={{
                ...bank,
                profile_id: i,
                credentials: profile.credentials,
              }}
              select={select?.profile_id == i}
              onSelectBank={setSelect}
            >
              <Typography variant="body1">{profile.name}</Typography>
            </BankCard>
          ))}
          <div />
          <SubmitButton disabled={!select} variant="contained" type="submit">
            <I18n select />
          </SubmitButton>
          <Button
            onClick={handleAccountIntegrationCancel}
            sx={{ marginLeft: "auto!important", marginTop: "6px!important" }}
          >
            <I18n close />
          </Button>
        </Stack>
      </Form>
    </PopupApplicationLayout>
  );
}
