import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export default forwardRef((props, ref) => {
  const { onChange, ...rest } = props;

  return (
    <NumericFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) =>
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        })
      }
      thousandSeparator
    />
  );
});
