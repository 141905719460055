import React, { useEffect, useRef } from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  code: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { model: i18n.t("verification_code") }),
    ),
});

export default function ({ flash }) {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(SCHEMA),
  });
  const ref = useRef();
  const code = watch("code");
  useEffect(() => {
    if (code.length == 6) {
      ref.current.submit();
    }
  }, [code]);

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form
        action={routes.user_two_factor_authentication()}
        method="put"
        ref={ref}
      >
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n enter_verification_code />
          </Typography>
          <Typography variant="subtitle2">
            <I18n verification_code_subtitle />
          </Typography>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("verification_code")}
            helperText={errors.code?.message}
            error={"code" in errors}
            {...register("code")}
            autoFocus={true}
          />
          <SubmitButton type="submit" variant="contained" size="large">
            <I18n sign_in />
          </SubmitButton>
          <SubmitButton
            href={routes.resend_code_user_two_factor_authentication()}
            fullWidth
            sx={{ marginY: 1 }}
          >
            <I18n send_again />
          </SubmitButton>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
