import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import BuildingOfficeIcon from "@heroicons/react/24/solid/BuildingOfficeIcon";
import ArrowsUpDownIcon from "@heroicons/react/24/solid/ArrowsUpDownIcon";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";
import routes from "../../utils/routes";

export const MainMenu = [
  { icon: BuildingOfficeIcon, title: "company_profile", route: routes.account },
  {
    icon: ArrowsUpDownIcon,
    title: "integrations",
    route: routes.account_integrations,
  },
  {
    icon: CurrencyDollarIcon,
    title: "currency_settings",
    route: routes.account_currencies,
  },
  { icon: UsersIcon, title: "users", route: routes.account_users },
];
