import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import "simplebar-react/dist/simplebar.min.css";
import { ApplicationLayout } from "./ApplicationLayout";
import { MainMenu } from "../layouts/UserApplicationMenu";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";

export const UserApplicationLayout = function ({ children, ...props }) {
  const currentPath = window.location.pathname;

  return (
    <ApplicationLayout {...props}>
      <Typography variant="h2">
        <I18n user_settings />
      </Typography>
      {MainMenu.length > 1 && (
        <Tabs value={currentPath} variant="scrollable" scrollButtons="auto">
          {MainMenu.map((item) => {
            return (
              <Tab
                component="a"
                key={item.title}
                label={i18n.t(item.title)}
                value={item.route()}
                href={item.route()}
                sx={{ px: 1 }}
              />
            );
          })}
        </Tabs>
      )}
      <Box component="main" sx={{ py: 4 }}>
        <Container
          sx={{ paddingLeft: "0!important", paddingRight: "0!important" }}
        >
          {children}
        </Container>
      </Box>
    </ApplicationLayout>
  );
};
