import React from "react";
import { RootApplicationLayout } from "../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import I18n from "../common/I18n";
import routes from "../../utils/routes";

export default function ({ flash }) {
  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Button
        href={routes.new_user_session()}
        variant="contained"
        size="large"
        fullWidth
        sx={{ marginY: 1 }}
      >
        <I18n sign_in />
      </Button>
      <Button
        href={routes.new_user_registration()}
        fullWidth
        sx={{ marginY: 1 }}
      >
        <I18n sign_up />
      </Button>
    </RootApplicationLayout>
  );
}
