import React from "react";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import Avatar from "@mui/material/Avatar";
import ArrowLongRightIcon from "@heroicons/react/24/solid/ArrowLongRightIcon";
import CURRENCY_ICONS from "../../../utils/currency-icons";
import currencyName from "../../../utils/currency-name";

export default function ({ bank_account, currency_pair, children }) {
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <div style={{ display: "flex" }}>
        <Avatar
          src={CURRENCY_ICONS[currency_pair.from]}
          sx={{ width: 36, height: 36 }}
        />
        <Avatar
          src={CURRENCY_ICONS[currency_pair.to]}
          sx={{ width: 36, height: 36, marginLeft: "-16px!important" }}
        />
      </div>
      <div style={{ marginLeft: 24 }}>
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          <b>{currencyName(currency_pair.from)}</b>
          <SvgIcon fontSize="small" sx={{ mx: 1 }}>
            <ArrowLongRightIcon />
          </SvgIcon>
          <b>{currencyName(currency_pair.to)}</b>
        </Typography>
        <div style={{ paddingTop: 4 }}>
          <Typography
            variant="subtitle2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Avatar
              variant="rounded"
              src={bank_account.image}
              sx={{ width: 24, height: 24, display: "inline-block" }}
            />
            &nbsp;&nbsp;
            {bank_account.name}
          </Typography>
          {children}
        </div>
      </div>
    </div>
  );
}
