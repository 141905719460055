import React from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  password: yup
    .string()
    .required(i18n.t("password_must_be_at_least_12_characters"))
    .min(12, i18n.t("password_must_be_at_least_12_characters"))
    .matches(
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/,
      i18n.t("password_must_be_at_least_12_characters"),
    ),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      i18n.t("you_have_to_type_same_password"),
    ),
});

export default function ({ flash, reset_password_token }) {
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form action={routes.user_password()} method="put">
        <input
          type="hidden"
          name="reset_password_token"
          value={reset_password_token}
        />
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n change_password />
          </Typography>
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("new_password")}
            helperText={errors.password?.message}
            error={"password" in errors}
            {...register("password")}
            autoFocus
          />
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("password_confirm")}
            helperText={errors.password_confirmation?.message}
            error={"password_confirmation" in errors}
            {...register("password_confirmation")}
          />
          <SubmitButton type="submit" variant="contained" size="large">
            <I18n change_password />
          </SubmitButton>
          <Button
            href={routes.new_user_session()}
            fullWidth
            sx={{ marginY: 1 }}
          >
            <I18n sign_in />
          </Button>
          <Button
            href={routes.new_user_registration()}
            fullWidth
            sx={{ marginY: 1 }}
          >
            <I18n sign_up />
          </Button>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
