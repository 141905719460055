import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  password: yup
    .string()
    .min(12, i18n.t("password_must_be_at_least_12_characters"))
    .matches(
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/,
      i18n.t("password_must_be_at_least_12_characters"),
    ),
  password_confirm: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      i18n.t("you_have_to_type_same_password"),
    ),
});

function EditDrawerContent() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      password_confirm: "",
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <Stack spacing={2}>
      <Form
        action={routes.user_security()}
        method="put"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Card>
          <CardHeader title={i18n.t("password")} />
          <Divider />
          <CardContent>
            <Stack spacing={3}>
              <TextField
                fullWidth
                required
                variant="standard"
                label={i18n.t("password")}
                type="password"
                {...register("password")}
                error={"password" in errors}
                helperText={errors.password?.message}
                autoFocus
              />
              <TextField
                fullWidth
                required
                variant="standard"
                label={i18n.t("password_confirm")}
                type="password"
                {...register("password_confirm")}
                error={"password_confirm" in errors}
                helperText={errors.password_confirm?.message}
              />
            </Stack>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton variant="contained" type="submit">
              <I18n update />
            </SubmitButton>
          </CardActions>
        </Card>
      </Form>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("security")}
      icon={PencilIcon}
    >
      <EditDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
