import React from "react";
import { RootApplicationLayout } from "../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import I18n from "../common/I18n";
import Form from "../common/Form";
import routes from "../../utils/routes";

export default function ({ flash }) {
  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form method="post" action={routes.sign_in()} data-turbo="false">
        <TextField fullWidth variant="standard" />
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          sx={{ marginY: 2 }}
        >
          <I18n sign_up />
        </Button>
        <input type="hidden" name="screen_hint" value="signup" />
        <input
          type="hidden"
          name="invitation"
          value="HPGxndlXLJCDXm5gAcgjDbwJquQAGD5D"
        />
        <input type="hidden" name="organization" value="org_0qAExGe7vYmw82wS" />
        <input
          type="hidden"
          name="organization_name"
          value="07c86fc253e538a918e5303b7b248162"
        />
      </Form>
    </RootApplicationLayout>
  );
}
