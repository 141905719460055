import React, { useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SvgIcon from "@mui/material/SvgIcon";
import Chip from "@mui/material/Chip";
import I18n from "../common/I18n";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";
import SolidCheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import { OnboardingApplicationLayout } from "../layouts/OnboardingApplicationLayout";
import routes from "../../utils/routes";

function HTML({ html }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ whiteSpace: "pre-wrap" }}
    />
  );
}

function UploadTask({ parent, task }) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const onChange = () => {
    setLoading(true);
    formRef.current.submit();
  };
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <form
        ref={formRef}
        action={routes.onboarding({ id: parent.gid })}
        encType="multipart/form-data"
        method="POST"
      >
        <input type="hidden" name="subtask_gid" value={task.gid} />
        <input type="hidden" name="type" value="upload" />
        <input type="hidden" name="_method" value="PUT" />
        <input
          type="hidden"
          name="authenticity_token"
          value={document.querySelector("meta[name='csrf-token']")?.content}
        />
        <LoadingButton
          loading={loading}
          component="label"
          variant="contained"
          startIcon={
            <SvgIcon>
              <CloudArrowUpIcon />
            </SvgIcon>
          }
        >
          <I18n upload />
          <input
            type="file"
            name="file"
            onChange={onChange}
            multiple
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
          />
        </LoadingButton>
      </form>
    </div>
  );
}

function Subtask({ parent, task }) {
  if (task.tags.includes("hidden")) return null;

  return (
    <Card>
      <CardHeader
        sx={task.completed ? { pt: 2 } : null}
        title={
          <>
            {task.completed ? (
              <SvgIcon
                color="success"
                sx={{ position: "relative", top: 6, mr: 1 }}
              >
                <SolidCheckCircleIcon />
              </SvgIcon>
            ) : (
              <SvgIcon
                color="action"
                sx={{ position: "relative", top: 6, mr: 1 }}
              >
                <CheckCircleIcon />
              </SvgIcon>
            )}
            {task.completed ? (
              <span style={{ color: "gray" }}>{task.name}</span>
            ) : (
              task.name
            )}
          </>
        }
      />
      {!task.completed && (
        <CardContent sx={{ pt: 0 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <HTML html={task.html_notes} />
          </Typography>
          {task.tags.includes("upload") && !task.completed && (
            <UploadTask parent={parent} task={task} />
          )}
          <Stack direction="row" spacing={1} sx={{ mt: 2, flexFlow: "wrap" }}>
            {task.tags.includes("upload") && task.attachments.length == 0 && (
              <I18n no_attachments />
            )}
            {task.attachments.map((attachment, i) => (
              <Chip
                key={i}
                label={attachment.name}
                icon={
                  <SvgIcon>
                    <CheckIcon />
                  </SvgIcon>
                }
                variant="outlined"
              />
            ))}
          </Stack>
        </CardContent>
      )}
    </Card>
  );
}

export default function ({ task }) {
  return (
    <OnboardingApplicationLayout>
      <Stack spacing={2} sx={{ mb: 4 }}>
        <Typography variant="h3">
          <I18n company_name />
          :&nbsp;
          {task.name}
        </Typography>
        <Typography variant="body1">
          <HTML html={task.html_notes} />
        </Typography>
        <Typography variant="body1">
          <I18n onboarding_tracker />:
          <br />
          <a href={routes.onboarding({ id: task.gid, with_host: true })}>
            {routes.onboarding({ id: task.gid, with_host: true })}
          </a>
        </Typography>
        {task.subtasks.map((subtask, i) => (
          <Subtask key={i} parent={task} task={subtask} />
        ))}
      </Stack>
    </OnboardingApplicationLayout>
  );
}
