import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const WarningTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  "&": {
    zIndex: 1100,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f04438",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f04438",
  },
}));

export default WarningTooltip;
