import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { AccountApplicationLayout } from "../layouts/AccountApplicationLayout";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import EditDrawer from "./EditDrawer";

export default function ({ ...props }) {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onClick = () => setOpen(true);
  const { account } = props;

  return (
    <AccountApplicationLayout {...props}>
      <Stack spacing={2}>
        <Card>
          <CardHeader
            title={i18n.t("company_name")}
            action={
              <Button onClick={onClick} variant="contained">
                <I18n edit />
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <Typography variant="body1" sx={{ my: 2 }}>
              {account.name}
            </Typography>
          </CardContent>
        </Card>
      </Stack>
      <EditDrawer open={open} onClose={onClose} {...props} />
    </AccountApplicationLayout>
  );
}
