import React from "react";
import dayjs from "dayjs";
import { Chart } from "react-google-charts";
import i18n from "../../utils/i18n";
import Typography from "@mui/material/Typography";
import I18n from "../common/I18n";
import currencyName, {
  numberToCurrency,
  numberToCurrencyNoUnit,
} from "../../utils/currency-name";

const CHART_OPTIONS = {
  isStacked: true,
  legend: { position: "none" },
  intervals: { style: "none" },
  bar: { groupWidth: "95%" },
  hAxis: { minorGridlines: { color: "transparent" } },
  vAxis: {
    minValue: 0,
    minorGridlines: { color: "transparent" },
    format: "decimal",
  },
  chartArea: { left: 80, top: 10, bottom: 20, width: "100%", height: "100%" },
};

function calcOpacity(color, opacity) {
  // calculate color with opacity
  const r = 255 - opacity * (255 - color.r);
  const g = 255 - opacity * (255 - color.g);
  const b = 255 - opacity * (255 - color.b);
  return `rgb(${Math.round(r)},${Math.round(g)},${Math.round(b)})`;
}
const BASE_COLORS = {
  inflow: { r: 28, g: 122, b: 252 },
  reserve: { r: 244, g: 113, b: 30 },
  payout: { r: 255, g: 0, b: 0 },
  treasury_in: { r: 43, g: 187, b: 127 },
  treasury_out: { r: 130, g: 181, b: 54 },
  fx_in: { r: 143, g: 126, b: 231 },
  fx_out: { r: 204, g: 81, b: 157 },
};
const COLORS = Object.keys(BASE_COLORS).reduce(
  (r, key) =>
    Object.assign(r, {
      [key]: [1.0, 0.9, 0.8, 0.7].map((opacity) =>
        calcOpacity(BASE_COLORS[key], opacity),
      ),
    }),
  {},
);

function transaction_direction(transaction, currency) {
  const src_currency = transaction.src_bank_account.currency;
  const dst_currency = transaction.dst_bank_account.currency;
  const src_bank_type = transaction.src_bank_account.bank_type;
  const dst_bank_type = transaction.dst_bank_account.bank_type;
  if (src_bank_type == "investment" && dst_bank_type != "investment") {
    // investment account => transactional/pseudo_transactional account
    //   same currency => treasury in
    //   dst_currency == currency => FX in
    //   src_currency == currency => FX out
    if (src_currency == dst_currency) return "treasury_in";
    else if (dst_currency == currency) return "fx_in";
    else if (src_currency == currency) return "fx_out";
  } else if (src_bank_type != "investment" && dst_bank_type == "investment")
    // transactional/pseudo_transactional account => investment account
    return "treasury_out"; // treasury out
}

export default function ({
  currency,
  upcoming_cashflow_items,
  upcoming_transactions,
  scheduled_cashflow_items,
  scheduled_transactions,
  onEditClick,
  onNewClick,
  fx_rates,
}) {
  const cashflow_items = scheduled_cashflow_items
    .concat(upcoming_cashflow_items)
    .filter((item) => !["canceled", "pending"].includes(item.status));
  const transactions = scheduled_transactions
    .concat(upcoming_transactions)
    .filter((item) => !["failed", "canceled"].includes(item.status));
  const data = [
    [""].concat(cashflow_items.map((item) => item.description)).concat(
      transactions.map((transaction) => {
        const direction = transaction_direction(transaction, currency);
        if (direction == "treasury_in")
          return `${i18n.t("treasury")} ⇒ ${
            transaction.dst_bank_account.short_name
          }`;
        if (direction == "treasury_out")
          return `${transaction.src_bank_account.short_name} ⇒ ${i18n.t(
            "treasury",
          )}`;
        if (direction == "fx_in")
          return `${currencyName(transaction.src_currency)} ⇒ ${
            transaction.dst_bank_account.short_name
          }`;
        if (direction == "fx_out")
          return `${i18n.t("treasury")} ⇒ ${currencyName(
            transaction.dst_currency,
          )}`;
      }),
    ),
  ].concat(
    [...Array(65)].map((_, i) => {
      const date = dayjs().add(i, "day");
      return [
        {
          v: date.toDate(),
          f: i18n.toTime("date.formats.short", date.toDate()),
        },
      ]
        .concat(
          cashflow_items.map((item) => {
            if (!date.isSame(item.date, "day")) return Number.NaN;
            if (item.currency != currency)
              return {
                v: -1 * fx_rates[item.currency][currency] * item.amount,
                f: numberToCurrency(Math.abs(item.amount), item.currency),
              };
            else
              return {
                v: -1 * item.amount,
                f: numberToCurrencyNoUnit(Math.abs(item.amount), item.currency),
              };
          }),
        )
        .concat(
          transactions.map((transaction) => {
            const direction = transaction_direction(transaction, currency);
            if (!date.isSame(transaction.completed_at, "day"))
              return Number.NaN;
            if (direction == "treasury_in")
              return {
                v: transaction.dst_amount,
                f: numberToCurrencyNoUnit(
                  transaction.dst_amount,
                  transaction.dst_currency,
                ),
              };
            if (direction == "treasury_out")
              return {
                v: -1 * transaction.src_amount,
                f: numberToCurrencyNoUnit(
                  transaction.src_amount,
                  transaction.src_currency,
                ),
              };
            if (direction == "fx_in")
              return {
                v: transaction.dst_amount,
                f: numberToCurrencyNoUnit(
                  transaction.dst_amount,
                  transaction.dst_currency,
                ),
              };
            if (direction == "fx_out") {
              const amount =
                transaction.src_amount == 0
                  ? transaction.dst_amount *
                    fx_rates[transaction.dst_currency][transaction.src_currency]
                  : transaction.src_amount;
              return {
                v: -1 * amount,
                f: numberToCurrency(amount, transaction.src_currency),
              };
            }
          }),
        );
    }),
  );
  let counts = {
    inflow: 0,
    reserve: 0,
    payout: 0,
    treasury_in: 0,
    treasury_out: 0,
    fx_in: 0,
    fx_out: 0,
  };
  const colors = cashflow_items
    .map((item) => {
      const item_type =
        item.amount < 0
          ? "inflow"
          : item.data?.recipient_id || item.status == "pending"
          ? "payout"
          : "reserve";
      const color =
        COLORS[item_type][counts[item_type] % COLORS[item_type].length];
      counts[item_type] += 1;
      return color;
    })
    .concat(
      transactions.map((transaction) => {
        const direction = transaction_direction(transaction, currency);
        const color =
          COLORS[direction][counts[direction] % COLORS[direction].length];
        counts[direction] += 1;
        return color;
      }),
    );

  if (cashflow_items.length + transactions.length == 0)
    return (
      <Typography
        onClick={onNewClick}
        variant="subtitle2"
        sx={{
          width: "100%",
          height: 250,
          p: 4,
          background: "#f6f6f6",
          cursor: "pointer",
        }}
      >
        <I18n no_scheduled_automation />
      </Typography>
    );

  return (
    <Chart
      width="100%"
      height="250px"
      chartType="ColumnChart"
      data={data}
      options={{ ...CHART_OPTIONS, colors }}
      chartEvents={[{ eventName: "select", callback: onEditClick }]}
    />
  );
}
