import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";

const CURRENCIES = i18n.t("currencies");

export default function ({ defaultValue, ...register }) {
  defaultValue = !defaultValue ? CURRENCIES[0].value : defaultValue;

  return (
    <FormControl
      fullWidth
      variant="standard"
      sx={{
        mt: 2,
        "& fieldset": { borderColor: "neutral.700" },
      }}
    >
      <InputLabel id="side-navigation-currency-select-label">
        <I18n currency />
      </InputLabel>
      <Select
        labelId="side-navigation-currency-select-label"
        label="Currency"
        sx={{ color: "inherit" }}
        defaultValue={defaultValue}
        {...register}
      >
        {CURRENCIES.map((_currency) => (
          <MenuItem
            key={_currency.value}
            value={_currency.value}
            sx={{ justifyContent: "center" }}
          >
            {_currency.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
