import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

class Dropdown extends React.Component {
  selectRef = null;

  render() {
    const {
      label,
      defaultValue,
      variant,
      fullWidth,
      values,
      sx,
      helperText,
      readOnly,
      size,
      required,
      ...register
    } = this.props;
    const labelId = (label || register?.name || "dummy").replace(
      /[^_0-9a-zA-Z]/g,
      "_",
    );
    let _defaultValue = defaultValue;
    if (!defaultValue) _defaultValue = values[0]?.value;

    return (
      <FormControl
        size={size}
        fullWidth={fullWidth}
        variant={variant || "standard"}
        sx={{
          mt: variant == "standard" && typeof sx?.mt == "undefined" ? 2 : 0,
          "& fieldset":
            variant == "standard" ? { borderColor: "neutral.700" } : {},
        }}
      >
        {label && <InputLabel id={"label-id-" + labelId}>{label}</InputLabel>}
        <Select
          ref={(c) => (this.selectRef = c)}
          required={required}
          labelId={"label-id-" + labelId}
          id={"select-id-" + labelId}
          label={label}
          sx={{ ...sx, color: "inherit" }}
          defaultValue={_defaultValue}
          inputProps={{ readOnly }}
          {...register}
        >
          {values.map((value) => (
            <MenuItem
              key={value.value}
              value={value.value}
              sx={{ justifyContent: "center" }}
            >
              {value.name}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

export default Dropdown;
