import React, { useState } from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

export default function ({ apps, popup, flash, ...props }) {
  const [name, setName] = useState(props.email);
  const [password, setPassword] = useState(props.password);

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form action={routes.user_session()} method="post">
        <input type="hidden" name="popup" value={popup} />
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n sign_in />
          </Typography>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("email_address")}
            name="user[email]"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus={true}
            autoComplete="email"
          />
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("password")}
            name="user[password]"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <Link
            href={routes.new_user_password()}
            underline="none"
            variant="body2"
          >
            <I18n forgot_password />
          </Link>
          <SubmitButton type="submit" variant="contained" size="large">
            <I18n sign_in />
          </SubmitButton>
          {apps.map((app) => (
            <Button
              key={app.id}
              variant="outlined"
              data-turbo={false}
              href={routes.initiate_account_app_integrations({
                app_id: app.id,
                login: true,
              })}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Avatar
                src={app.image}
                variant="rounded"
                sx={{ height: 36, width: 36 }}
              />
              <Typography variant="body2" sx={{ px: 4 }}>
                <b>
                  <I18n login_with name={app.name} />
                </b>
              </Typography>
            </Button>
          ))}
          <Button href={routes.new_user_registration()} fullWidth>
            <I18n sign_up />
          </Button>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
