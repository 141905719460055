import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import i18n from "../../utils/i18n";
import TransactionFormDrawer from "../transactions/FormDrawer";
import CashflowFormDrawer from "../cashflows/FormDrawer";
import TransactionRecord from "../transactions/TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import dayjs from "dayjs";
import CashflowAlert from "../cashflows/CashflowAlert";

function AlertDrawerContent({
  currency,
  fx_rates,
  current_user,
  openCashflowForm,
  openTransactionForm,
  inapproval_transactions,
  pending_cashflow_items,
  failed_cashflow_items,
  failed_transactions,
  account_balance_warnings,
  bank_account_warnings,
}) {
  const pendingItems = pending_cashflow_items.sort((a, b) =>
    (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
  );
  const failedItems = failed_cashflow_items
    .concat(failed_transactions)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
    );

  return (
    <Stack spacing={2}>
      {account_balance_warnings.length > 0 && (
        <Card>
          <CardHeader title={i18n.t("below_threshold_balance")} />
          <Divider />
          <CardContent>
            {account_balance_warnings.map((item, i) => (
              <CashflowAlert
                key={i}
                item={item}
                openCashflowForm={openCashflowForm}
              />
            ))}
          </CardContent>
        </Card>
      )}
      {bank_account_warnings.length > 0 && (
        <Card>
          <CardHeader title={i18n.t("bank_account_warnings")} />
          <Divider />
          <CardContent>
            {bank_account_warnings.map((item, i) => (
              <CashflowAlert key={i} item={item} />
            ))}
          </CardContent>
        </Card>
      )}
      {pendingItems.length > 0 && (
        <Card>
          <CardHeader
            title={i18n.t("pending_transactions")}
            subheader={i18n.t("pending_transactions_subtitle")}
          />
          <Divider />
          <CardContent>
            {pendingItems.map((item, i) => (
              <CashflowItem
                key={i}
                item={item}
                onClick={() => openCashflowForm(item)}
              />
            ))}
          </CardContent>
        </Card>
      )}
      {inapproval_transactions.length > 0 && (
        <Card>
          <CardHeader
            title={i18n.t("inapproval_transactions")}
            subheader={i18n.t("inapproval_transactions_subtitle")}
          />
          <Divider />
          <CardContent>
            {inapproval_transactions.map((item, i) => (
              <TransactionRecord
                key={i}
                transaction={item}
                currency={currency}
                readOnly={true}
                fx_rates={fx_rates}
              />
            ))}
          </CardContent>
        </Card>
      )}
      {failedItems.length > 0 && (
        <Card>
          <CardHeader
            title={i18n.t("failed_transactions")}
            subheader={i18n.t("failed_transactions_subtitle")}
          />
          <Divider />
          <CardContent>
            {failedItems.map(
              (item, i) =>
                (item.date && (
                  <CashflowItem
                    key={i}
                    item={item}
                    onClick={() => openCashflowForm(item)}
                  />
                )) || (
                  <TransactionRecord
                    key={i}
                    transaction={item}
                    currency={currency}
                    readOnly={current_user.role_value == "read_only"}
                    fx_rates={fx_rates}
                    onClick={
                      item.status == "placed"
                        ? () => openTransactionForm(item)
                        : undefined
                    }
                  />
                ),
            )}
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, current_user, fx_rates } = props;
  const [cashflowForm, setCashflowForm] = useState(false);
  const [transactionForm, setTransactionForm] = useState(false);
  const openCashflowForm = (item) => setCashflowForm(item);
  const closeCashflowForm = () => setCashflowForm(false);
  const openTransactionForm = (item) => setTransactionForm(item);
  const closeTransactionForm = () => setTransactionForm(false);
  const defaultDay = dayjs();

  return (
    <>
      <RightDrawerLayout
        open={open}
        onClose={onClose}
        title={i18n.t("failed_pending_transactions")}
        icon={ExclamationTriangleIcon}
      >
        <AlertDrawerContent
          openCashflowForm={openCashflowForm}
          openTransactionForm={openTransactionForm}
          closeCashflowForm={closeCashflowForm}
          closeTransactionForm={closeTransactionForm}
          {...props}
        />
      </RightDrawerLayout>

      <TransactionFormDrawer
        open={!!transactionForm}
        onClose={closeTransactionForm}
        defaultCurrency={transactionForm?.dst_currency}
        item={transactionForm}
        fx_rates={fx_rates}
        src_bank_accounts={[transactionForm?.src_bank_account]}
        dst_bank_accounts={[transactionForm?.dst_bank_account]}
        balance_src_bank_accounts={[]}
        balance_dst_bank_accounts={[]}
        current_user={current_user}
      />
      <CashflowFormDrawer
        open={!!cashflowForm}
        onClose={closeCashflowForm}
        item={cashflowForm}
        bank_accounts={[cashflowForm?.bank_account]}
        defaultCurrency={cashflowForm?.currency}
        defaultDay={defaultDay}
        current_user={current_user}
      />
    </>
  );
}
