import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import { Form } from "../common/Form";
import SubmitButton from "../common/SubmitButton";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";

const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("name") })),
});

function EditDrawerContent({ account }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: account.name,
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <Stack spacing={2}>
      <Form
        action={routes.account()}
        method="put"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Card>
          <CardHeader title={i18n.t("company_name")} />
          <Divider />
          <CardContent>
            <TextField
              fullWidth
              required
              variant="standard"
              label={i18n.t("company_name")}
              helperText={errors.name?.message}
              {...register("name")}
              error={"name" in errors}
              autoFocus
            />
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton variant="contained" type="submit">
              <I18n update />
            </SubmitButton>
          </CardActions>
        </Card>
      </Form>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("company_profile")}
      icon={PencilIcon}
    >
      <EditDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
