import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { AccountApplicationLayout } from "../../layouts/AccountApplicationLayout";
import CurrencyCard from "./CurrencyCard";
import NewDrawer from "./NewDrawer";
import EditDrawer from "./EditDrawer";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";

export default function ({ bank_accounts, ...props }) {
  const { account } = props;
  const [openNew, setOpenNew] = useState();
  const [openEdit, setOpenEdit] = useState();
  const onClickNew = () => setOpenNew(true);
  const onCloseNew = () => setOpenNew(undefined);
  const onClickEdit = (selection) => setOpenEdit(selection);
  const onCloseEdit = () => setOpenEdit(undefined);

  return (
    <AccountApplicationLayout {...props}>
      <Stack spacing={2}>
        <Card>
          <CardHeader title={i18n.t("fx_transaction")} />
          <Divider />
          <CardContent>
            {bank_accounts.map((bank_account) =>
              bank_account.profile.supported_fx_withdrawals.map(
                (currency_pair, i) => (
                  <Grid key={i} container>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      onClick={() =>
                        onClickEdit({ bank_account, currency_pair })
                      }
                      sx={{
                        p: 2,
                        cursor: "pointer",
                        "&:hover": { border: 0.5, borderColor: "#6366F1" },
                        "&:not(:last-child)": {
                          borderBottom: "solid 0.5px #eee",
                        },
                      }}
                    >
                      <CurrencyCard
                        bank_account={bank_account}
                        currency_pair={currency_pair}
                      >
                        <Typography
                          variant="body2"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <I18n trailing_stop />
                          &nbsp;&nbsp;&nbsp;
                          <Switch
                            checked={bank_account.profile.enable_trailing_stop}
                          />
                        </Typography>
                        {bank_account.profile.enable_trailing_stop && (
                          <Typography variant="body2" sx={{ display: "flex" }}>
                            <I18n drop_rate />
                            &nbsp;&nbsp;&nbsp;
                            <b>{bank_account.profile.trailing_stop_rate}%</b>
                          </Typography>
                        )}
                      </CurrencyCard>
                    </Grid>
                  </Grid>
                ),
              ),
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title={i18n.t("fx_payout")}
            action={
              <Button onClick={onClickNew} variant="contained">
                <I18n edit />
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <Typography variant="body1">
              <I18n fx_safety_margin />
              :&nbsp;
              <b>{account.profile.fx_safety_margin * 100.0}%</b>
            </Typography>
            <Typography variant="subtitle2">
              <I18n fx_safety_margin_subtitle />
            </Typography>
          </CardContent>
        </Card>
      </Stack>
      <NewDrawer open={openNew} onClose={onCloseNew} account={account} />
      <EditDrawer
        open={openEdit}
        onClose={onCloseEdit}
        bank_account={openEdit?.bank_account}
        currency_pair={openEdit?.currency_pair}
      />
    </AccountApplicationLayout>
  );
}
