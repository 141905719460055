import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import ShieldCheckIcon from "@heroicons/react/24/solid/ShieldCheckIcon";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import routes from "../../utils/routes";

export const MainMenu = [
  { icon: UserCircleIcon, title: "user_profile", route: routes.user },
  { icon: ShieldCheckIcon, title: "security", route: routes.user_security },
  {
    icon: BellAlertIcon,
    title: "notification",
    route: routes.user_notification,
  },
];
