import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import UserPlusIcon from "@heroicons/react/24/solid/UserPlusIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import Dropdown from "../../common/Dropdown";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("name") })),
  email: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("email_address") }),
    )
    .email(i18n.t("field_is_incorrect", { field: i18n.t("email_address") })),
});

function NewDrawerContent({ excludeOwner }) {
  const ROLES = i18n
    .t("roles")
    .filter((role) => (excludeOwner ? role.name != "Owner" : true));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <Stack spacing={2}>
      <Form
        action={routes.account_users()}
        method="post"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Card>
          <CardHeader title={i18n.t("user")} />
          <Divider />
          <CardContent>
            <Stack spacing={4}>
              <TextField
                fullWidth
                required
                variant="standard"
                label={i18n.t("user_name")}
                helperText={errors.name?.message}
                {...register("name")}
                error={"name" in errors}
                autoFocus
              />
              <TextField
                fullWidth
                required
                variant="standard"
                label={i18n.t("email_address")}
                helperText={errors.name?.message}
                {...register("email")}
                error={"email" in errors}
              />
              <Dropdown
                fullWidth
                label={i18n.t("role")}
                variant="standard"
                values={ROLES}
                {...register("role")}
              />
            </Stack>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton variant="contained" type="submit">
              <I18n invite />
            </SubmitButton>
          </CardActions>
        </Card>
      </Form>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("invite_user")}
      icon={UserPlusIcon}
    >
      <NewDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
