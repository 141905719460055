import React from "react";
import { navigator } from "@hotwired/turbo"; // See: https://discuss.hotwired.dev/t/triggering-turbo-frame-with-js/1622/30

export const submitForm = (form) => navigator.submitForm(form);

// When used with react-hook-form, onSubmit should be described as follows:
//   onSubmit={(event)=> handleSubmit(()=> undefined, ()=> event.preventDefault())()}
// reference: https://github.com/react-hook-form/react-hook-form/issues/9774
export const Form = React.forwardRef(function (
  { action, method, disableTurbo, children, ...props },
  ref,
) {
  const authenticity_token = document.querySelector(
    "meta[name='csrf-token']",
  )?.content;
  let formMethod = method;
  let hiddenMethod;
  if (["put", "patch", "delete"].includes(method)) {
    formMethod = "post";
    hiddenMethod = method;
  }

  return (
    <form
      action={action}
      method={formMethod}
      {...props}
      data-turbo={disableTurbo ? "false" : ""}
      ref={ref}
    >
      {hiddenMethod && (
        <input type="hidden" name="_method" value={hiddenMethod} />
      )}
      {authenticity_token && (
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticity_token}
        />
      )}
      {children}
    </form>
  );
});

export default Form;
