import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { ThemeProvider } from "@mui/material/styles";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import "simplebar-react/dist/simplebar.min.css";
import { createTheme } from "../theme";
import Flash from "../common/Flash";
import { SideNavigation } from "./SideNavigation";
import { ConsoleApplicationMenu } from "../layouts/ConsoleApplicationMenu";
import { LayoutRoot, LayoutContainer } from "./LayoutContainer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton } from "@mui/material";

export const ConsoleApplicationLayout = function ({
  current_user,
  flash,
  children,
}) {
  const [openNav, setOpenNav] = useState(false);
  const theme = createTheme();
  const mdUp = useMediaQuery(() => theme.breakpoints.up("md"));

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <SideNavigation
          onClose={() => setOpenNav(false)}
          open={openNav}
          current_user={current_user}
          menu={ConsoleApplicationMenu}
        >
          <Box sx={{ ml: 2, my: 1 }}>
            <Typography
              variant="h6"
              sx={{ color: "#faaf47", fontSize: "1.0rem" }}
            >
              Operation Console
            </Typography>
          </Box>
        </SideNavigation>
        <LayoutRoot>
          <LayoutContainer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 4,
              }}
            >
              {!mdUp && (
                <IconButton
                  onClick={() => setOpenNav(true)}
                  sx={{ position: "fixed", left: 16, top: 16 }}
                >
                  <SvgIcon>
                    <Bars3Icon />
                  </SvgIcon>
                </IconButton>
              )}
              <Flash flash={flash} />
              <Container maxWidth="lg">{children}</Container>
            </Box>
          </LayoutContainer>
        </LayoutRoot>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
