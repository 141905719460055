import React from "react";
import Typography from "@mui/material/Typography";
import { AnalysisApplicationLayout } from "../../layouts/AnalysisApplicationLayout";
import I18n from "../../common/I18n";

export default function ({ email }) {
  return (
    <AnalysisApplicationLayout title="thank_you">
      <Typography variant="h2" sx={{ my: 6, fontSize: "1.5rem" }}>
        <I18n thank_you />
      </Typography>
      <Typography variant="subtitle2" sx={{ my: 2 }}>
        <b>
          <I18n sent_pdf_report email={email} />
        </b>
        <br />
        <I18n sent_pdf_report_sub />
      </Typography>
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1Qt0kfBnicdiVQMngMwXLzjX4S0RZzCMxO6a5YIQgJrYQnN00XDWAgwL1if4KY5afUmxFZbWd_?gv=true"
        style={{ border: 0 }}
        width="100%"
        height="600"
        frameBorder="0"
      />
    </AnalysisApplicationLayout>
  );
}
